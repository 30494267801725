export enum FavouritesGTMEvent {
    FAVOURITE = "favourite_events",
    COMPARE = "compare_events"
}

export enum FavouritesGTMAction {
    FAVOURITES_ADD_TO_FAVOURITE = "add_to_favourite",
    FAVOURITES_DELETE_FROM_FAVOURITE = "delete_from_favourite",
    FAVOURITES_MULTILEAD_ADD_TO_FAVOURITE = "add_to_favourite_text",
    FAVOURITES_MULTILEAD_MODAL_OPEN_BUTTON_CLICK = "lead_for_all_click",
    FAVOURITES_MULTILEAD_SEND_LEAD_BUTTON_CLICK = "lead_for_all_send",
    ADD_TO_COMPARE = "add_to_compare",
    DELETE_FROM_COMPARE = "delete_from_compare",
    ADD_PLACE = "add_place"
}
export enum FavouritesGTMEvent {
    FAVOURITE = "favourite_events",
    COMPARE = "compare_events"
}

export enum FavouritesGTMAction {
    FAVOURITES_ADD_TO_FAVOURITE = "add_to_favourite",
    FAVOURITES_DELETE_FROM_FAVOURITE = "delete_from_favourite",
    FAVOURITES_MULTILEAD_ADD_TO_FAVOURITE = "add_to_favourite_text",
    FAVOURITES_MULTILEAD_MODAL_OPEN_BUTTON_CLICK = "lead_for_all_click",
    FAVOURITES_MULTILEAD_SEND_LEAD_BUTTON_CLICK = "lead_for_all_send",
    ADD_TO_COMPARE = "add_to_compare",
    DELETE_FROM_COMPARE = "delete_from_compare",
    ADD_PLACE = "add_place"
}
