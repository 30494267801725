import {useEffect, useState} from "react";

export const useBottomNavBarOffset = () => {
    const [isMobileWidth, setMobileWidth] = useState(false);
    useEffect(() => {
        setMobileWidth(window.innerWidth < 1024);
        if (isMobileWidth) {
            const body = document.getElementsByTagName("body");
            body[0].classList.add("mobile-contact-bar-offset");
        }
    }, [isMobileWidth]);
};
import {useEffect, useState} from "react";

export const useBottomNavBarOffset = () => {
    const [isMobileWidth, setMobileWidth] = useState(false);
    useEffect(() => {
        setMobileWidth(window.innerWidth < 1024);
        if (isMobileWidth) {
            const body = document.getElementsByTagName("body");
            body[0].classList.add("mobile-contact-bar-offset");
        }
    }, [isMobileWidth]);
};
