import React from "react";
import {css, Theme} from "@emotion/react";

import {flexAlignCenter, ml, mr} from "@pg-design/helpers-css";
import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

type IProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    className?: string;
    direction: "next" | "prev";
};

export const NavButton: React.FC<IProps> = (props) => {
    const {children, className, direction, ...restProps} = props;

    const isNext = direction === "next";

    return (
        <button css={navButton} className={className} {...restProps}>
            {!isNext && <ChevronLeftIcon css={mr(2)} size="2" />}

            <Text css={text} variant="button_small">
                {children}
            </Text>

            {isNext && <ChevronRightIcon css={ml(2)} size="2" />}
        </button>
    );
};

const navButton = (theme: Theme) => css`
    border: none;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    ${flexAlignCenter};

    & > svg {
        fill: #fff;
    }

    &:disabled,
    &[disabled] {
        cursor: none;
        color: ${theme.colors.gray[700]};
        pointer-events: none;

        & > svg {
            fill: ${theme.colors.gray[700]};
        }
    }

    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus,
    &:active.focus {
        color: ${theme.colors.highlight};

        & > svg {
            fill: ${theme.colors.highlight};
        }
    }
`;

const text = (theme: Theme) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.md}) {
        display: block;
    }
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {flexAlignCenter, ml, mr} from "@pg-design/helpers-css";
import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

type IProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    className?: string;
    direction: "next" | "prev";
};

export const NavButton: React.FC<IProps> = (props) => {
    const {children, className, direction, ...restProps} = props;

    const isNext = direction === "next";

    return (
        <button css={navButton} className={className} {...restProps}>
            {!isNext && <ChevronLeftIcon css={mr(2)} size="2" />}

            <Text css={text} variant="button_small">
                {children}
            </Text>

            {isNext && <ChevronRightIcon css={ml(2)} size="2" />}
        </button>
    );
};

const navButton = (theme: Theme) => css`
    border: none;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    ${flexAlignCenter};

    & > svg {
        fill: #fff;
    }

    &:disabled,
    &[disabled] {
        cursor: none;
        color: ${theme.colors.gray[700]};
        pointer-events: none;

        & > svg {
            fill: ${theme.colors.gray[700]};
        }
    }

    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus,
    &:active.focus {
        color: ${theme.colors.highlight};

        & > svg {
            fill: ${theme.colors.highlight};
        }
    }
`;

const text = (theme: Theme) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.md}) {
        display: block;
    }
`;
