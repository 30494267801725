import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const HorizontalListIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9 2H1v.615h8V2ZM1 5.692h6.147v.615H1v-.615ZM1 7.539h8v.615H1v-.615ZM1 3.846h5.539v.615H1v-.615Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const HorizontalListIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9 2H1v.615h8V2ZM1 5.692h6.147v.615H1v-.615ZM1 7.539h8v.615H1v-.615ZM1 3.846h5.539v.615H1v-.615Z" />
        </SvgIcon>
    );
};
