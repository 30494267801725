import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";
import {FavouritesGTMAction, FavouritesGTMEvent} from "./favourites_gtm_tracking";

export const gtmFavouriteAddToFavouritesClick = (isChecked: boolean) => {
    const eventData = {
        event: FavouritesGTMEvent.FAVOURITE,
        action: isChecked ? FavouritesGTMAction.FAVOURITES_ADD_TO_FAVOURITE : FavouritesGTMAction.FAVOURITES_DELETE_FROM_FAVOURITE
    };

    hitGoogleTagManager(eventData);
};

export const gtmFavouritesMultileadAddToFavourites = () => {
    const eventData = {event: FavouritesGTMEvent.FAVOURITE, action: FavouritesGTMAction.FAVOURITES_MULTILEAD_ADD_TO_FAVOURITE};

    hitGoogleTagManager(eventData);
};

export const gtmFavouritesMultileadModalOpen = () => {
    const eventData = {
        event: FavouritesGTMEvent.FAVOURITE,
        action: FavouritesGTMAction.FAVOURITES_MULTILEAD_MODAL_OPEN_BUTTON_CLICK
    };

    hitGoogleTagManager(eventData);
};

export const gtmFavouritesMultileadSendLead = () => {
    const eventData = {
        event: FavouritesGTMEvent.FAVOURITE,
        action: FavouritesGTMAction.FAVOURITES_MULTILEAD_SEND_LEAD_BUTTON_CLICK
    };

    hitGoogleTagManager(eventData);
};
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";
import {FavouritesGTMAction, FavouritesGTMEvent} from "./favourites_gtm_tracking";

export const gtmFavouriteAddToFavouritesClick = (isChecked: boolean) => {
    const eventData = {
        event: FavouritesGTMEvent.FAVOURITE,
        action: isChecked ? FavouritesGTMAction.FAVOURITES_ADD_TO_FAVOURITE : FavouritesGTMAction.FAVOURITES_DELETE_FROM_FAVOURITE
    };

    hitGoogleTagManager(eventData);
};

export const gtmFavouritesMultileadAddToFavourites = () => {
    const eventData = {event: FavouritesGTMEvent.FAVOURITE, action: FavouritesGTMAction.FAVOURITES_MULTILEAD_ADD_TO_FAVOURITE};

    hitGoogleTagManager(eventData);
};

export const gtmFavouritesMultileadModalOpen = () => {
    const eventData = {
        event: FavouritesGTMEvent.FAVOURITE,
        action: FavouritesGTMAction.FAVOURITES_MULTILEAD_MODAL_OPEN_BUTTON_CLICK
    };

    hitGoogleTagManager(eventData);
};

export const gtmFavouritesMultileadSendLead = () => {
    const eventData = {
        event: FavouritesGTMEvent.FAVOURITE,
        action: FavouritesGTMAction.FAVOURITES_MULTILEAD_SEND_LEAD_BUTTON_CLICK
    };

    hitGoogleTagManager(eventData);
};
