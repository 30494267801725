import {hitGoogleTagManager, IGtmData} from "../init_google_tag_manager";

interface IGtmPhoneNumberCall extends IGtmData {
    event: "phone_number_call";
    vendorId: number;
    offerId?: number;
    propertyId?: number;
}

type IHitFunction = (gtmData: IGtmPhoneNumberCall) => void;

const createGtmPhoneNumberCall =
    (hitFunction: IHitFunction) =>
    (vendorId: number, offerId?: number, propertyId?: number): boolean => {
        // prepare gtm data and hit
        const gtmData: IGtmPhoneNumberCall = {
            event: "phone_number_call",
            vendorId,
            offerId,
            propertyId
        };
        hitFunction(gtmData);
        return true;
    };

export const frontendGtmPhoneNumberCall = createGtmPhoneNumberCall(hitGoogleTagManager);
import {hitGoogleTagManager, IGtmData} from "../init_google_tag_manager";

interface IGtmPhoneNumberCall extends IGtmData {
    event: "phone_number_call";
    vendorId: number;
    offerId?: number;
    propertyId?: number;
}

type IHitFunction = (gtmData: IGtmPhoneNumberCall) => void;

const createGtmPhoneNumberCall =
    (hitFunction: IHitFunction) =>
    (vendorId: number, offerId?: number, propertyId?: number): boolean => {
        // prepare gtm data and hit
        const gtmData: IGtmPhoneNumberCall = {
            event: "phone_number_call",
            vendorId,
            offerId,
            propertyId
        };
        hitFunction(gtmData);
        return true;
    };

export const frontendGtmPhoneNumberCall = createGtmPhoneNumberCall(hitGoogleTagManager);
