import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";
import {FavouritesGTMAction, FavouritesGTMEvent} from "./favourites_gtm_tracking";

export const hitGtmAddedNewPlace = (placeName: string) => {
    hitGoogleTagManager({
        event: FavouritesGTMEvent.COMPARE,
        action: FavouritesGTMAction.ADD_PLACE,
        label: placeName
    });
};

export const hitGtmAddToCompareClick = (isCheked: boolean) => {
    hitGoogleTagManager({
        event: FavouritesGTMEvent.FAVOURITE,
        action: isCheked ? FavouritesGTMAction.ADD_TO_COMPARE : FavouritesGTMAction.DELETE_FROM_COMPARE
    });
};
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";
import {FavouritesGTMAction, FavouritesGTMEvent} from "./favourites_gtm_tracking";

export const hitGtmAddedNewPlace = (placeName: string) => {
    hitGoogleTagManager({
        event: FavouritesGTMEvent.COMPARE,
        action: FavouritesGTMAction.ADD_PLACE,
        label: placeName
    });
};

export const hitGtmAddToCompareClick = (isCheked: boolean) => {
    hitGoogleTagManager({
        event: FavouritesGTMEvent.FAVOURITE,
        action: isCheked ? FavouritesGTMAction.ADD_TO_COMPARE : FavouritesGTMAction.DELETE_FROM_COMPARE
    });
};
