import {useState} from "react";
import {css, Interpolation, Theme} from "@emotion/react";

import {InputRange, IRange} from "@pg-design/inputs-module";

import {FakeSelectWithModal} from "../../../offer/components/offer_modal/FakeSelectWithModal";
import {SearchFilterLabel} from "../../../search/components/filters/SearchFilterLabel";
import {FilterFooter} from "../../../search/components/filters/SearchFiltersFooter";
import {getAreaFilterLabelText} from "../../utils/filter_labels";

interface IProps {
    initialValue?: IFilterValue;
    rightDropdown?: boolean;
    onConfirm?: (value: IFilterValue) => void;
    handleClear?: () => void;
    aplaCss?: Interpolation<Theme>;
    labelRenderer?: (value: IFilterValue) => Parameters<typeof FakeSelectWithModal>[0]["label"];
    onDropdownOpen?: () => void;
    onDropdownClose?: () => void;
    className?: string;
}

type IFilterValue = IRange<string>;

const emptyValue: IFilterValue = {lower: "", upper: ""};
const areaRound = 5;
const name = "area";

export function AreaFilter(props: IProps) {
    const {initialValue, onConfirm, handleClear, rightDropdown, className} = props;
    const [value, setValue] = useState<IFilterValue>(initialValue ? initialValue : emptyValue);

    const handlers = {
        confirm: (values: IFilterValue) => {
            if (onConfirm) {
                onConfirm(values);
            }
        },
        clear: () => {
            setValue(emptyValue);

            if (handleClear) {
                handleClear();
            }
        }
    };

    return (
        <FakeSelectWithModal
            label={props.labelRenderer ? props.labelRenderer(value) : getAreaFilterLabel(value, "Metraż")}
            isEmpty={!initialValue || (initialValue && initialValue.lower === "" && initialValue.upper === "")}
            onClear={handlers.clear}
            rightDropdown={rightDropdown}
            aplaCss={props.aplaCss}
            onDropdownOpen={props.onDropdownOpen}
            onDropdownClose={props.onDropdownClose}
            className={className}
        >
            {(props) => (
                <>
                    <SearchFilterLabel label="Metraż" htmlFor={`${name}_lower`} />
                    <InputRange
                        value={value}
                        name={`${name}-range`}
                        onChange={(_, value) => {
                            setValue(value as IFilterValue);
                        }}
                        hasDashSeparator
                        roundToMultiplicationOf={areaRound}
                    />

                    <FilterFooter
                        onCancel={() => {
                            handlers.clear();
                            props.close();
                        }}
                        onSave={() => {
                            handlers.confirm(value);
                            props.close();
                        }}
                    />
                </>
            )}
        </FakeSelectWithModal>
    );
}

const getAreaFilterLabel = (value: IRange<string>, defaultLabel: string) => {
    const {label, text} = getAreaFilterLabelText(value, defaultLabel);

    if (text) {
        return (
            <>
                {label}
                <br />
                <span css={subLabel}>
                    {text}
                    <span className="nowrap">
                        m<sup>2</sup>
                    </span>
                </span>
            </>
        );
    }

    return label;
};

const subLabel = (theme: Theme) => css`
    font-size: ${theme.globals.text.root};
    color: ${theme.colors.gray[700]};
    white-space: nowrap;
    display: flex;
    overflow: hidden;
`;
import {useState} from "react";
import {css, Interpolation, Theme} from "@emotion/react";

import {InputRange, IRange} from "@pg-design/inputs-module";

import {FakeSelectWithModal} from "../../../offer/components/offer_modal/FakeSelectWithModal";
import {SearchFilterLabel} from "../../../search/components/filters/SearchFilterLabel";
import {FilterFooter} from "../../../search/components/filters/SearchFiltersFooter";
import {getAreaFilterLabelText} from "../../utils/filter_labels";

interface IProps {
    initialValue?: IFilterValue;
    rightDropdown?: boolean;
    onConfirm?: (value: IFilterValue) => void;
    handleClear?: () => void;
    aplaCss?: Interpolation<Theme>;
    labelRenderer?: (value: IFilterValue) => Parameters<typeof FakeSelectWithModal>[0]["label"];
    onDropdownOpen?: () => void;
    onDropdownClose?: () => void;
    className?: string;
}

type IFilterValue = IRange<string>;

const emptyValue: IFilterValue = {lower: "", upper: ""};
const areaRound = 5;
const name = "area";

export function AreaFilter(props: IProps) {
    const {initialValue, onConfirm, handleClear, rightDropdown, className} = props;
    const [value, setValue] = useState<IFilterValue>(initialValue ? initialValue : emptyValue);

    const handlers = {
        confirm: (values: IFilterValue) => {
            if (onConfirm) {
                onConfirm(values);
            }
        },
        clear: () => {
            setValue(emptyValue);

            if (handleClear) {
                handleClear();
            }
        }
    };

    return (
        <FakeSelectWithModal
            label={props.labelRenderer ? props.labelRenderer(value) : getAreaFilterLabel(value, "Metraż")}
            isEmpty={!initialValue || (initialValue && initialValue.lower === "" && initialValue.upper === "")}
            onClear={handlers.clear}
            rightDropdown={rightDropdown}
            aplaCss={props.aplaCss}
            onDropdownOpen={props.onDropdownOpen}
            onDropdownClose={props.onDropdownClose}
            className={className}
        >
            {(props) => (
                <>
                    <SearchFilterLabel label="Metraż" htmlFor={`${name}_lower`} />
                    <InputRange
                        value={value}
                        name={`${name}-range`}
                        onChange={(_, value) => {
                            setValue(value as IFilterValue);
                        }}
                        hasDashSeparator
                        roundToMultiplicationOf={areaRound}
                    />

                    <FilterFooter
                        onCancel={() => {
                            handlers.clear();
                            props.close();
                        }}
                        onSave={() => {
                            handlers.confirm(value);
                            props.close();
                        }}
                    />
                </>
            )}
        </FakeSelectWithModal>
    );
}

const getAreaFilterLabel = (value: IRange<string>, defaultLabel: string) => {
    const {label, text} = getAreaFilterLabelText(value, defaultLabel);

    if (text) {
        return (
            <>
                {label}
                <br />
                <span css={subLabel}>
                    {text}
                    <span className="nowrap">
                        m<sup>2</sup>
                    </span>
                </span>
            </>
        );
    }

    return label;
};

const subLabel = (theme: Theme) => css`
    font-size: ${theme.globals.text.root};
    color: ${theme.colors.gray[700]};
    white-space: nowrap;
    display: flex;
    overflow: hidden;
`;
