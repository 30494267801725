import {hitAlgolytics} from "@pg-mono/algolytics";

import {ApplicationSource, ApplicationSourceSection, getApplicationSourceDisplay} from "../../application/utils/ApplicationSource";
import {MyFavouritesListNavigationItemValue} from "../../my_offers/constants/my_offers_navigation_options";
import {ViewType} from "../../view_type/ViewType";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export enum FavouritesListCollectionName {
    MY_LIST_COLLECTION = "my_list",
    MY_LIST_OPEN = "my_list_open",
    MY_LIST_BUTTONS = "my_list_buttons",
    MY_LIST_CHECKED = "my_list_checked",
    APPLICATION_OPEN = "application_open",
    COMPARISON_TOOL = "comparison",
    FAVOURITES_MULTILEAD = "favourites_lead_for_all_send"
}

export enum FavouritesListEventTypes {
    FAVOURITES_LIST_MY_LIST_CLICK = "show_my_list",
    FAVOURITES_LIST_APPLIED_LIST_CLICK = "show_my_application",
    FAVOURITES_LIST_RENDER = "my_list_open",
    FAVOURITES_LIST_INTERACTION = "my_list_interaction",
    FAVOURITES_LIST_APPLICATIONS_BUTTON = "my_applications_button_click",
    FAVOURITES_LIST_COMPARISON_TOOL_BUTTON = "comparison_button_click",
    POPUP_DISPLAY = "popup_display"
}

export enum FavouritesListConfirmationButton {
    APPLIED_LIST = "applied_list",
    COMPARISON_TOOL = "comparison_tool"
}

export const handleFavouritesListRenderHit = (offersIds: number[], propertiesIds: number[]) => {
    const payload = {
        ...getTrackedSiteData(),
        view_type: ViewType.MY_FAVOURITES_LIST,
        event_type: FavouritesListEventTypes.FAVOURITES_LIST_RENDER,
        offers: offersIds,
        properties: propertiesIds
    };

    hitAlgolytics(FavouritesListCollectionName.MY_LIST_OPEN, payload);
};

interface IFavouriteMultileadData {
    type: string;
    id: number;
}

export const handleFavouritesListApplicationOpenHit = (onlyOffers?: boolean) => {
    const applicationSource = onlyOffers ? ApplicationSource.FavouritesOfferInquiry : ApplicationSource.FavouritesPropertyInquiry;

    const payload = {
        ...getTrackedSiteData(),
        view_type: ViewType.MY_FAVOURITES_LIST,
        source: getApplicationSourceDisplay(applicationSource),
        source_id: applicationSource,
        source_section: ApplicationSourceSection.FAVOURITES
    };

    hitAlgolytics(FavouritesListCollectionName.APPLICATION_OPEN, payload);
};

export const handleFavouritesListCheckHit = (checked: boolean, offerId: number, propertyId?: number) => {
    const payload = {
        ...getTrackedSiteData(),
        event_type: FavouritesListEventTypes.FAVOURITES_LIST_INTERACTION,
        offer_id: offerId,
        property_id: propertyId ? propertyId : null,
        checked
    };

    hitAlgolytics(FavouritesListCollectionName.MY_LIST_CHECKED, payload);
};

export const handleComparisonModalRenderHit = () => {
    const payload = {
        ...getTrackedSiteData(),
        view_type: ViewType.MY_FAVOURITES_LIST,
        event_type: FavouritesListEventTypes.POPUP_DISPLAY
    };

    hitAlgolytics(FavouritesListCollectionName.COMPARISON_TOOL, payload);
};

export const handleGoToAppliedModalButtonClickHit = () => {
    const payload = {
        event_type: FavouritesListEventTypes.FAVOURITES_LIST_APPLICATIONS_BUTTON,
        ...getTrackedSiteData(),
        view_type: ViewType.MY_FAVOURITES_LIST
    };

    hitAlgolytics(FavouritesListCollectionName.MY_LIST_BUTTONS, payload);
};

export const handleComparisonModalButtonClickHit = () => {
    const payload = {
        ...getTrackedSiteData(),
        view_type: ViewType.MY_FAVOURITES_LIST,
        event_type: FavouritesListEventTypes.FAVOURITES_LIST_COMPARISON_TOOL_BUTTON
    };

    hitAlgolytics(FavouritesListCollectionName.COMPARISON_TOOL, payload);
};

export const handleFavouritesListNavigationClickHit = (value: boolean | string | number) => {
    let payload = {};

    if (value === MyFavouritesListNavigationItemValue.LIST) {
        payload = {
            event_type: FavouritesListEventTypes.FAVOURITES_LIST_MY_LIST_CLICK
        };
    } else {
        payload = {
            event_type: FavouritesListEventTypes.FAVOURITES_LIST_APPLIED_LIST_CLICK
        };
    }

    payload = {
        ...payload,
        ...getTrackedSiteData(),
        view_type: ViewType.MY_FAVOURITES_LIST
    };

    hitAlgolytics(FavouritesListCollectionName.MY_LIST_BUTTONS, payload);
};

export const handleFavouritesMultiApplicationHit = (data: IFavouriteMultileadData[]) => {
    const payload = {
        ...getTrackedSiteData(),
        view_type: ViewType.MY_FAVOURITES_LIST,
        data
    };

    hitAlgolytics(FavouritesListCollectionName.FAVOURITES_MULTILEAD, payload);
};
import {hitAlgolytics} from "@pg-mono/algolytics";

import {ApplicationSource, ApplicationSourceSection, getApplicationSourceDisplay} from "../../application/utils/ApplicationSource";
import {MyFavouritesListNavigationItemValue} from "../../my_offers/constants/my_offers_navigation_options";
import {ViewType} from "../../view_type/ViewType";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export enum FavouritesListCollectionName {
    MY_LIST_COLLECTION = "my_list",
    MY_LIST_OPEN = "my_list_open",
    MY_LIST_BUTTONS = "my_list_buttons",
    MY_LIST_CHECKED = "my_list_checked",
    APPLICATION_OPEN = "application_open",
    COMPARISON_TOOL = "comparison",
    FAVOURITES_MULTILEAD = "favourites_lead_for_all_send"
}

export enum FavouritesListEventTypes {
    FAVOURITES_LIST_MY_LIST_CLICK = "show_my_list",
    FAVOURITES_LIST_APPLIED_LIST_CLICK = "show_my_application",
    FAVOURITES_LIST_RENDER = "my_list_open",
    FAVOURITES_LIST_INTERACTION = "my_list_interaction",
    FAVOURITES_LIST_APPLICATIONS_BUTTON = "my_applications_button_click",
    FAVOURITES_LIST_COMPARISON_TOOL_BUTTON = "comparison_button_click",
    POPUP_DISPLAY = "popup_display"
}

export enum FavouritesListConfirmationButton {
    APPLIED_LIST = "applied_list",
    COMPARISON_TOOL = "comparison_tool"
}

export const handleFavouritesListRenderHit = (offersIds: number[], propertiesIds: number[]) => {
    const payload = {
        ...getTrackedSiteData(),
        view_type: ViewType.MY_FAVOURITES_LIST,
        event_type: FavouritesListEventTypes.FAVOURITES_LIST_RENDER,
        offers: offersIds,
        properties: propertiesIds
    };

    hitAlgolytics(FavouritesListCollectionName.MY_LIST_OPEN, payload);
};

interface IFavouriteMultileadData {
    type: string;
    id: number;
}

export const handleFavouritesListApplicationOpenHit = (onlyOffers?: boolean) => {
    const applicationSource = onlyOffers ? ApplicationSource.FavouritesOfferInquiry : ApplicationSource.FavouritesPropertyInquiry;

    const payload = {
        ...getTrackedSiteData(),
        view_type: ViewType.MY_FAVOURITES_LIST,
        source: getApplicationSourceDisplay(applicationSource),
        source_id: applicationSource,
        source_section: ApplicationSourceSection.FAVOURITES
    };

    hitAlgolytics(FavouritesListCollectionName.APPLICATION_OPEN, payload);
};

export const handleFavouritesListCheckHit = (checked: boolean, offerId: number, propertyId?: number) => {
    const payload = {
        ...getTrackedSiteData(),
        event_type: FavouritesListEventTypes.FAVOURITES_LIST_INTERACTION,
        offer_id: offerId,
        property_id: propertyId ? propertyId : null,
        checked
    };

    hitAlgolytics(FavouritesListCollectionName.MY_LIST_CHECKED, payload);
};

export const handleComparisonModalRenderHit = () => {
    const payload = {
        ...getTrackedSiteData(),
        view_type: ViewType.MY_FAVOURITES_LIST,
        event_type: FavouritesListEventTypes.POPUP_DISPLAY
    };

    hitAlgolytics(FavouritesListCollectionName.COMPARISON_TOOL, payload);
};

export const handleGoToAppliedModalButtonClickHit = () => {
    const payload = {
        event_type: FavouritesListEventTypes.FAVOURITES_LIST_APPLICATIONS_BUTTON,
        ...getTrackedSiteData(),
        view_type: ViewType.MY_FAVOURITES_LIST
    };

    hitAlgolytics(FavouritesListCollectionName.MY_LIST_BUTTONS, payload);
};

export const handleComparisonModalButtonClickHit = () => {
    const payload = {
        ...getTrackedSiteData(),
        view_type: ViewType.MY_FAVOURITES_LIST,
        event_type: FavouritesListEventTypes.FAVOURITES_LIST_COMPARISON_TOOL_BUTTON
    };

    hitAlgolytics(FavouritesListCollectionName.COMPARISON_TOOL, payload);
};

export const handleFavouritesListNavigationClickHit = (value: boolean | string | number) => {
    let payload = {};

    if (value === MyFavouritesListNavigationItemValue.LIST) {
        payload = {
            event_type: FavouritesListEventTypes.FAVOURITES_LIST_MY_LIST_CLICK
        };
    } else {
        payload = {
            event_type: FavouritesListEventTypes.FAVOURITES_LIST_APPLIED_LIST_CLICK
        };
    }

    payload = {
        ...payload,
        ...getTrackedSiteData(),
        view_type: ViewType.MY_FAVOURITES_LIST
    };

    hitAlgolytics(FavouritesListCollectionName.MY_LIST_BUTTONS, payload);
};

export const handleFavouritesMultiApplicationHit = (data: IFavouriteMultileadData[]) => {
    const payload = {
        ...getTrackedSiteData(),
        view_type: ViewType.MY_FAVOURITES_LIST,
        data
    };

    hitAlgolytics(FavouritesListCollectionName.FAVOURITES_MULTILEAD, payload);
};
