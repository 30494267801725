import React, {useMemo, useState} from "react";
import {useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {flexAbsoluteCenter, flexDirection, linkPseudoStateUnStyled, mb, ml, mr, mt, mv} from "@pg-design/helpers-css";
import {PhoneIcon} from "@pg-design/icons-module";
import {Link} from "@pg-design/link-module";
import {Loader} from "@pg-design/loader-module";
import {Highlight, Text} from "@pg-design/text-module";
import {phoneNumberFormat, priceFormat} from "@pg-mono/string-utils";

import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {gtmRotatingPhoneNumbersCall, gtmRotatingPhoneNumbersReveal} from "../../tracking/google_tag_manager/gtm_rotating_phone_numbers";
import {useClipPhoneNumber} from "../hooks/use_clip_phone_number";

interface IProps {
    isAutoresponderEnabled: boolean;
    isDataFetched: boolean;
    offer?: IOfferDetailApplication | null;
    propertyId?: number;
    propertyPrice?: number | null;
    optionalOfferPhoneData?: {
        configuration: {
            phone_clip: string | null;
        };
        is_phone_clip_enabled: boolean;
    };
}

export const NO_CONTACT_DATA = "BRAK DANYCH KONTAKTOWYCH";

export const ApplicationPhoneNumber = (props: IProps) => {
    const theme = useTheme();

    const [isPhoneNumberVisible, setIsPhoneNumberVisible] = useState(false);

    const realPhoneNumber =
        (props.offer?.sales_offices.length && props.offer?.sales_offices[0].phone) ||
        (props.offer?.sales_offices.length && props.offer?.sales_offices[0].mobile);
    const fallbackPhoneNumber = useMemo(
        () =>
            (props.optionalOfferPhoneData?.is_phone_clip_enabled && props.optionalOfferPhoneData.configuration.phone_clip) ||
            (props.isDataFetched && props.offer?.is_phone_clip_enabled && props.offer?.configuration.phone_clip) ||
            (props.isDataFetched &&
                props.offer?.sales_offices &&
                props.offer?.sales_offices.length &&
                (props.offer.sales_offices[0].phone || props.offer.sales_offices[0].mobile)) ||
            null,
        [props.optionalOfferPhoneData, props.isDataFetched, props.offer]
    );

    const showPhoneNumberContent = async () => {
        setIsPhoneNumberVisible(true);
        gtmRotatingPhoneNumbersReveal();
        await fetchClipPhoneNumber(props.propertyId);
    };

    const {phoneNumber: clipPhone, fetchClipPhoneNumber, isFetching, isClipPhoneBlocked} = useClipPhoneNumber(fallbackPhoneNumber);
    const phoneNumber = isClipPhoneBlocked ? realPhoneNumber : clipPhone;
    return !props.isAutoresponderEnabled ? (
        <>
            {phoneNumber !== null && (
                <Text align="center" css={mv(2)} color={theme.colors.gray[700]} variant="body_copy_2">
                    lub zadzwoń
                </Text>
            )}

            {!isPhoneNumberVisible ? (
                <div css={[mb(3), flexAbsoluteCenter]}>
                    <Button onClick={showPhoneNumberContent} type="button" variant="outlined_secondary" css={flexAbsoluteCenter}>
                        <PhoneIcon size="2.4" wrapperSize="4" css={mr(2)} />
                        POKAŻ NUMER
                    </Button>
                </div>
            ) : (
                <div css={flexAbsoluteCenter}>
                    {isFetching ? (
                        <Loader size="sm" />
                    ) : phoneNumber ? (
                        <div css={[flexAbsoluteCenter, flexDirection("column")]}>
                            <div css={[flexAbsoluteCenter, mb(2)]}>
                                <PhoneIcon size="2.4" wrapperColor={theme.colors.primary} wrapperSize="4" />
                                <Link css={[ml(2), linkPseudoStateUnStyled]} href={`tel:+${phoneNumber}`} onClick={() => gtmRotatingPhoneNumbersCall()}>
                                    <Text as="span" variant="headline_6" color={theme.colors.secondary}>
                                        {phoneNumberFormat(phoneNumber)}
                                    </Text>
                                </Link>
                            </div>

                            {props.propertyPrice && (
                                <>
                                    <Text as="span" variant="body_copy_2" color={theme.colors.secondary} css={mb(1)}>
                                        Cena tej nieruchomości:
                                    </Text>
                                    <Text as="span" variant="body_copy_0" align="center" color={theme.colors.secondary} css={mb(2)}>
                                        <Highlight strong>{priceFormat(props.propertyPrice as number)}</Highlight>
                                    </Text>
                                </>
                            )}
                        </div>
                    ) : (
                        <Text as="span" variant="headline_6" align="center" color={theme.colors.secondary} css={mt(2)}>
                            {NO_CONTACT_DATA}
                        </Text>
                    )}
                </div>
            )}
        </>
    ) : null;
};
import React, {useMemo, useState} from "react";
import {useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {flexAbsoluteCenter, flexDirection, linkPseudoStateUnStyled, mb, ml, mr, mt, mv} from "@pg-design/helpers-css";
import {PhoneIcon} from "@pg-design/icons-module";
import {Link} from "@pg-design/link-module";
import {Loader} from "@pg-design/loader-module";
import {Highlight, Text} from "@pg-design/text-module";
import {phoneNumberFormat, priceFormat} from "@pg-mono/string-utils";

import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {gtmRotatingPhoneNumbersCall, gtmRotatingPhoneNumbersReveal} from "../../tracking/google_tag_manager/gtm_rotating_phone_numbers";
import {useClipPhoneNumber} from "../hooks/use_clip_phone_number";

interface IProps {
    isAutoresponderEnabled: boolean;
    isDataFetched: boolean;
    offer?: IOfferDetailApplication | null;
    propertyId?: number;
    propertyPrice?: number | null;
    optionalOfferPhoneData?: {
        configuration: {
            phone_clip: string | null;
        };
        is_phone_clip_enabled: boolean;
    };
}

export const NO_CONTACT_DATA = "BRAK DANYCH KONTAKTOWYCH";

export const ApplicationPhoneNumber = (props: IProps) => {
    const theme = useTheme();

    const [isPhoneNumberVisible, setIsPhoneNumberVisible] = useState(false);

    const realPhoneNumber =
        (props.offer?.sales_offices.length && props.offer?.sales_offices[0].phone) ||
        (props.offer?.sales_offices.length && props.offer?.sales_offices[0].mobile);
    const fallbackPhoneNumber = useMemo(
        () =>
            (props.optionalOfferPhoneData?.is_phone_clip_enabled && props.optionalOfferPhoneData.configuration.phone_clip) ||
            (props.isDataFetched && props.offer?.is_phone_clip_enabled && props.offer?.configuration.phone_clip) ||
            (props.isDataFetched &&
                props.offer?.sales_offices &&
                props.offer?.sales_offices.length &&
                (props.offer.sales_offices[0].phone || props.offer.sales_offices[0].mobile)) ||
            null,
        [props.optionalOfferPhoneData, props.isDataFetched, props.offer]
    );

    const showPhoneNumberContent = async () => {
        setIsPhoneNumberVisible(true);
        gtmRotatingPhoneNumbersReveal();
        await fetchClipPhoneNumber(props.propertyId);
    };

    const {phoneNumber: clipPhone, fetchClipPhoneNumber, isFetching, isClipPhoneBlocked} = useClipPhoneNumber(fallbackPhoneNumber);
    const phoneNumber = isClipPhoneBlocked ? realPhoneNumber : clipPhone;
    return !props.isAutoresponderEnabled ? (
        <>
            {phoneNumber !== null && (
                <Text align="center" css={mv(2)} color={theme.colors.gray[700]} variant="body_copy_2">
                    lub zadzwoń
                </Text>
            )}

            {!isPhoneNumberVisible ? (
                <div css={[mb(3), flexAbsoluteCenter]}>
                    <Button onClick={showPhoneNumberContent} type="button" variant="outlined_secondary" css={flexAbsoluteCenter}>
                        <PhoneIcon size="2.4" wrapperSize="4" css={mr(2)} />
                        POKAŻ NUMER
                    </Button>
                </div>
            ) : (
                <div css={flexAbsoluteCenter}>
                    {isFetching ? (
                        <Loader size="sm" />
                    ) : phoneNumber ? (
                        <div css={[flexAbsoluteCenter, flexDirection("column")]}>
                            <div css={[flexAbsoluteCenter, mb(2)]}>
                                <PhoneIcon size="2.4" wrapperColor={theme.colors.primary} wrapperSize="4" />
                                <Link css={[ml(2), linkPseudoStateUnStyled]} href={`tel:+${phoneNumber}`} onClick={() => gtmRotatingPhoneNumbersCall()}>
                                    <Text as="span" variant="headline_6" color={theme.colors.secondary}>
                                        {phoneNumberFormat(phoneNumber)}
                                    </Text>
                                </Link>
                            </div>

                            {props.propertyPrice && (
                                <>
                                    <Text as="span" variant="body_copy_2" color={theme.colors.secondary} css={mb(1)}>
                                        Cena tej nieruchomości:
                                    </Text>
                                    <Text as="span" variant="body_copy_0" align="center" color={theme.colors.secondary} css={mb(2)}>
                                        <Highlight strong>{priceFormat(props.propertyPrice as number)}</Highlight>
                                    </Text>
                                </>
                            )}
                        </div>
                    ) : (
                        <Text as="span" variant="headline_6" align="center" color={theme.colors.secondary} css={mt(2)}>
                            {NO_CONTACT_DATA}
                        </Text>
                    )}
                </div>
            )}
        </>
    ) : null;
};
