import {useEffect, useMemo, useRef, useState} from "react";
import {useTheme} from "@emotion/react";

import {isEqual} from "@pg-mono/nodash";
import {IPolyline} from "@pg-mono/open-street-map";
import {RequestState} from "@pg-mono/request-state";

import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {IOSMPoisRoutesGrouped} from "../../maps/actions/fetch_osm_pois_routes_grouped";
import {getRouteGeometry} from "../../maps/api/get_route_geometry";
import {IPublicTransportRoute} from "../../maps/types/IPublicTransportRoute";
import {IPublicTransportRouteWithStops} from "../../maps/types/IPublicTransportRouteWithStops";
import {IPublicTransportStop} from "../../maps/types/IPublicTransportStop";
import {IPublicTransportType} from "../../maps/types/IPublicTransportType";
import {getMapPassiveTransportStopMarker} from "../../maps/utils/get_map_passive_transport_stop_marker";
import {getMapTransportPolyline} from "../../maps/utils/get_map_transport_polyline";

interface IUseOfferListMapPoisParams {
    shouldFetch: boolean;
    routes: (IOSMPoisRoutesGrouped | null)[] | null;
}

interface IUseOfferListMapData {
    geometries:
        | {
              train: IPublicTransportRouteWithStops[] | null;
              tram: IPublicTransportRouteWithStops[] | null;
              subway: IPublicTransportRouteWithStops[] | null;
          }[]
        | null;
}

export function useOfferListMapRailTransportElements(params: IUseOfferListMapPoisParams) {
    const {shouldFetch, routes} = params;
    const theme = useTheme();
    const [requestState, setRequestState] = useState(RequestState.None);
    const [mapData, setMapData] = useState<IUseOfferListMapData | null>(null);
    const prevRoutesRef = useRef<(IOSMPoisRoutesGrouped | null)[] | null>(null);

    useEffect(() => {
        if (shouldFetch && routes && isRoutesChanged(prevRoutesRef.current, routes)) {
            prevRoutesRef.current = routes;
            setRequestState(RequestState.Waiting);

            // TODO: in the future it will be good to add additional memoization, e.g. using RTK-Query
            getMapRailTransportData(routes)
                .then((data) => {
                    setRequestState(RequestState.Success);
                    setMapData(data);
                })
                .catch(() => {
                    // allow to refresh routes on error
                    prevRoutesRef.current = null;
                    setRequestState(RequestState.Error);
                });
        }
    }, [shouldFetch, routes]);

    useEffect(() => {
        if (mapData && routes === null) {
            setMapData(null);
        }
    }, [routes]);

    const polylines = useMemo(
        () =>
            mapData?.geometries
                ?.map((regionGeometries) => {
                    const tramPolylines = regionGeometries.tram?.map((tramRoute) => getMapTransportPolyline(tramRoute, theme));
                    const trainPolylines = regionGeometries.train?.map((trainRoute) => getMapTransportPolyline(trainRoute, theme));
                    const subwayPolylines = regionGeometries.subway?.map((subwayRoute) => getMapTransportPolyline(subwayRoute, theme));

                    return ([] as IPolyline[])
                        .concat(tramPolylines || [])
                        .concat(trainPolylines || [])
                        .concat(subwayPolylines || []);
                })
                .flat(),
        [mapData]
    );

    const markers = useMemo(
        () =>
            mapData?.geometries
                ?.map((regionGeometries) => {
                    const regionTramStops = getRouteStops(getUniqueStops(regionGeometries.tram), IPublicTransportType.TRAM);
                    const regionTrainStops = getRouteStops(getUniqueStops(regionGeometries.train), IPublicTransportType.TRAIN);
                    const regionSubwayStops = getRouteStops(getUniqueStops(regionGeometries.subway), IPublicTransportType.SUBWAY);

                    return [...regionTramStops, ...regionTrainStops, ...regionSubwayStops];
                })
                .flat(),
        [mapData]
    );

    return {
        polylines,
        markers,
        requestState
    };
}

//  Diff check
function isRoutesChanged(prevRoutes: (IOSMPoisRoutesGrouped | null)[] | null, currentRoutes: (IOSMPoisRoutesGrouped | null)[] | null) {
    if (prevRoutes && currentRoutes) {
        const regionRoutesDifference = currentRoutes.map((currentRouteGroup, i) => {
            const prevRouteGroup = prevRoutes[i];
            if (prevRouteGroup && currentRouteGroup) {
                return isRouteGroupDifferent(prevRouteGroup, currentRouteGroup);
            }

            return isSimplyDifferent(prevRouteGroup, currentRouteGroup);
        });

        return Boolean(regionRoutesDifference.find((regionRoutesCheck) => Boolean(regionRoutesCheck)));
    }

    return isSimplyDifferent(prevRoutes, currentRoutes);
}

function isRouteGroupDifferent(prevRouteGroup: IOSMPoisRoutesGrouped, currentRouteGroup: IOSMPoisRoutesGrouped) {
    const prevRouteGroupKeys = Object.keys(prevRouteGroup) as (keyof IOSMPoisRoutesGrouped)[];
    const nextRouteGroupKeys = Object.keys(currentRouteGroup) as (keyof IOSMPoisRoutesGrouped)[];

    if (prevRouteGroupKeys.length !== nextRouteGroupKeys.length) {
        return true;
    }

    return Boolean(
        prevRouteGroupKeys.find((routeGroupKey) => {
            const prevRouteCollection = prevRouteGroup[routeGroupKey] || null;
            const currentRouteCollection = currentRouteGroup[routeGroupKey] || null;
            return isTransportGroupDifferent(prevRouteCollection, currentRouteCollection);
        })
    );
}

function isTransportGroupDifferent(prevRoutes: IPublicTransportRoute[] | null, currentRoutes: IPublicTransportRoute[] | null) {
    if (prevRoutes && currentRoutes) {
        return isRouteCollectionDifferent(prevRoutes, currentRoutes);
    }

    return isSimplyDifferent(prevRoutes, currentRoutes);
}

function isRouteCollectionDifferent(prevRoutes: IPublicTransportRoute[], currentRoutes: IPublicTransportRoute[]) {
    const prevRouteCollectionIds = prevRoutes.map((route) => route.id);
    const currentRouteCollectionIds = currentRoutes.map((route) => route.id);

    return !isEqual(prevRouteCollectionIds, currentRouteCollectionIds);
}

function isSimplyDifferent(prev: unknown | null, current: unknown | null) {
    if (!prev && !current) {
        return false;
    }

    return true;
}

//  Api
function getRouteStops(stops: IPublicTransportStop[], routeType: IPublicTransportType) {
    const validStops = stops.filter((stop) => stop.coordinates[0] !== null && stop.coordinates[1] !== null);

    return validStops.map((stop) => getMapPassiveTransportStopMarker(stop, routeType, null));
}

async function getMapRailTransportData(routes: (IOSMPoisRoutesGrouped | null)[]) {
    const geometries = await getMapRailRouteGeometries(routes);

    return {
        geometries
    };
}

async function getMapRailRouteGeometries(regionRoutes: (IOSMPoisRoutesGrouped | null)[]) {
    const mapRailRouteGeometries = regionRoutes
        ? regionRoutes
              .filter((regionTransportRoutes) => Boolean(regionTransportRoutes))
              .map((regionTransportRoutes) => getRegionRailRouteGeometries(regionTransportRoutes as IOSMPoisRoutesGrouped))
        : null;

    return mapRailRouteGeometries ? Promise.all(mapRailRouteGeometries) : null;
}

async function getRegionRailRouteGeometries(regionRoutes: IOSMPoisRoutesGrouped) {
    const {train, tram, subway} = regionRoutes;

    return {
        train: await getRouteGeometries(train),
        tram: await getRouteGeometries(tram),
        subway: await getRouteGeometries(subway)
    };
}

async function getRouteGeometries(routes?: IPublicTransportRoute[]) {
    if (!routes) {
        return null;
    }

    const routeGeometries = routes.map((route) => getRouteGeometry({poiId: route.id}));

    try {
        return await Promise.all(routeGeometries);
    } catch (error) {
        notifyBugsnag(error, "route-geometry (numlabs) failed");
        return null;
    }
}

//  Utils
function getUniqueStops(routes: IPublicTransportRouteWithStops[] | null) {
    if (!routes) {
        return [];
    }

    const regionStops = routes.map((route) => route.stops).flat();
    const routeStopsSet = new Set();

    return regionStops.filter((stop) => {
        const isDuplicate = routeStopsSet.has(stop.id);
        routeStopsSet.add(stop.id);

        return !isDuplicate;
    });
}
import {useEffect, useMemo, useRef, useState} from "react";
import {useTheme} from "@emotion/react";

import {isEqual} from "@pg-mono/nodash";
import {IPolyline} from "@pg-mono/open-street-map";
import {RequestState} from "@pg-mono/request-state";

import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {IOSMPoisRoutesGrouped} from "../../maps/actions/fetch_osm_pois_routes_grouped";
import {getRouteGeometry} from "../../maps/api/get_route_geometry";
import {IPublicTransportRoute} from "../../maps/types/IPublicTransportRoute";
import {IPublicTransportRouteWithStops} from "../../maps/types/IPublicTransportRouteWithStops";
import {IPublicTransportStop} from "../../maps/types/IPublicTransportStop";
import {IPublicTransportType} from "../../maps/types/IPublicTransportType";
import {getMapPassiveTransportStopMarker} from "../../maps/utils/get_map_passive_transport_stop_marker";
import {getMapTransportPolyline} from "../../maps/utils/get_map_transport_polyline";

interface IUseOfferListMapPoisParams {
    shouldFetch: boolean;
    routes: (IOSMPoisRoutesGrouped | null)[] | null;
}

interface IUseOfferListMapData {
    geometries:
        | {
              train: IPublicTransportRouteWithStops[] | null;
              tram: IPublicTransportRouteWithStops[] | null;
              subway: IPublicTransportRouteWithStops[] | null;
          }[]
        | null;
}

export function useOfferListMapRailTransportElements(params: IUseOfferListMapPoisParams) {
    const {shouldFetch, routes} = params;
    const theme = useTheme();
    const [requestState, setRequestState] = useState(RequestState.None);
    const [mapData, setMapData] = useState<IUseOfferListMapData | null>(null);
    const prevRoutesRef = useRef<(IOSMPoisRoutesGrouped | null)[] | null>(null);

    useEffect(() => {
        if (shouldFetch && routes && isRoutesChanged(prevRoutesRef.current, routes)) {
            prevRoutesRef.current = routes;
            setRequestState(RequestState.Waiting);

            // TODO: in the future it will be good to add additional memoization, e.g. using RTK-Query
            getMapRailTransportData(routes)
                .then((data) => {
                    setRequestState(RequestState.Success);
                    setMapData(data);
                })
                .catch(() => {
                    // allow to refresh routes on error
                    prevRoutesRef.current = null;
                    setRequestState(RequestState.Error);
                });
        }
    }, [shouldFetch, routes]);

    useEffect(() => {
        if (mapData && routes === null) {
            setMapData(null);
        }
    }, [routes]);

    const polylines = useMemo(
        () =>
            mapData?.geometries
                ?.map((regionGeometries) => {
                    const tramPolylines = regionGeometries.tram?.map((tramRoute) => getMapTransportPolyline(tramRoute, theme));
                    const trainPolylines = regionGeometries.train?.map((trainRoute) => getMapTransportPolyline(trainRoute, theme));
                    const subwayPolylines = regionGeometries.subway?.map((subwayRoute) => getMapTransportPolyline(subwayRoute, theme));

                    return ([] as IPolyline[])
                        .concat(tramPolylines || [])
                        .concat(trainPolylines || [])
                        .concat(subwayPolylines || []);
                })
                .flat(),
        [mapData]
    );

    const markers = useMemo(
        () =>
            mapData?.geometries
                ?.map((regionGeometries) => {
                    const regionTramStops = getRouteStops(getUniqueStops(regionGeometries.tram), IPublicTransportType.TRAM);
                    const regionTrainStops = getRouteStops(getUniqueStops(regionGeometries.train), IPublicTransportType.TRAIN);
                    const regionSubwayStops = getRouteStops(getUniqueStops(regionGeometries.subway), IPublicTransportType.SUBWAY);

                    return [...regionTramStops, ...regionTrainStops, ...regionSubwayStops];
                })
                .flat(),
        [mapData]
    );

    return {
        polylines,
        markers,
        requestState
    };
}

//  Diff check
function isRoutesChanged(prevRoutes: (IOSMPoisRoutesGrouped | null)[] | null, currentRoutes: (IOSMPoisRoutesGrouped | null)[] | null) {
    if (prevRoutes && currentRoutes) {
        const regionRoutesDifference = currentRoutes.map((currentRouteGroup, i) => {
            const prevRouteGroup = prevRoutes[i];
            if (prevRouteGroup && currentRouteGroup) {
                return isRouteGroupDifferent(prevRouteGroup, currentRouteGroup);
            }

            return isSimplyDifferent(prevRouteGroup, currentRouteGroup);
        });

        return Boolean(regionRoutesDifference.find((regionRoutesCheck) => Boolean(regionRoutesCheck)));
    }

    return isSimplyDifferent(prevRoutes, currentRoutes);
}

function isRouteGroupDifferent(prevRouteGroup: IOSMPoisRoutesGrouped, currentRouteGroup: IOSMPoisRoutesGrouped) {
    const prevRouteGroupKeys = Object.keys(prevRouteGroup) as (keyof IOSMPoisRoutesGrouped)[];
    const nextRouteGroupKeys = Object.keys(currentRouteGroup) as (keyof IOSMPoisRoutesGrouped)[];

    if (prevRouteGroupKeys.length !== nextRouteGroupKeys.length) {
        return true;
    }

    return Boolean(
        prevRouteGroupKeys.find((routeGroupKey) => {
            const prevRouteCollection = prevRouteGroup[routeGroupKey] || null;
            const currentRouteCollection = currentRouteGroup[routeGroupKey] || null;
            return isTransportGroupDifferent(prevRouteCollection, currentRouteCollection);
        })
    );
}

function isTransportGroupDifferent(prevRoutes: IPublicTransportRoute[] | null, currentRoutes: IPublicTransportRoute[] | null) {
    if (prevRoutes && currentRoutes) {
        return isRouteCollectionDifferent(prevRoutes, currentRoutes);
    }

    return isSimplyDifferent(prevRoutes, currentRoutes);
}

function isRouteCollectionDifferent(prevRoutes: IPublicTransportRoute[], currentRoutes: IPublicTransportRoute[]) {
    const prevRouteCollectionIds = prevRoutes.map((route) => route.id);
    const currentRouteCollectionIds = currentRoutes.map((route) => route.id);

    return !isEqual(prevRouteCollectionIds, currentRouteCollectionIds);
}

function isSimplyDifferent(prev: unknown | null, current: unknown | null) {
    if (!prev && !current) {
        return false;
    }

    return true;
}

//  Api
function getRouteStops(stops: IPublicTransportStop[], routeType: IPublicTransportType) {
    const validStops = stops.filter((stop) => stop.coordinates[0] !== null && stop.coordinates[1] !== null);

    return validStops.map((stop) => getMapPassiveTransportStopMarker(stop, routeType, null));
}

async function getMapRailTransportData(routes: (IOSMPoisRoutesGrouped | null)[]) {
    const geometries = await getMapRailRouteGeometries(routes);

    return {
        geometries
    };
}

async function getMapRailRouteGeometries(regionRoutes: (IOSMPoisRoutesGrouped | null)[]) {
    const mapRailRouteGeometries = regionRoutes
        ? regionRoutes
              .filter((regionTransportRoutes) => Boolean(regionTransportRoutes))
              .map((regionTransportRoutes) => getRegionRailRouteGeometries(regionTransportRoutes as IOSMPoisRoutesGrouped))
        : null;

    return mapRailRouteGeometries ? Promise.all(mapRailRouteGeometries) : null;
}

async function getRegionRailRouteGeometries(regionRoutes: IOSMPoisRoutesGrouped) {
    const {train, tram, subway} = regionRoutes;

    return {
        train: await getRouteGeometries(train),
        tram: await getRouteGeometries(tram),
        subway: await getRouteGeometries(subway)
    };
}

async function getRouteGeometries(routes?: IPublicTransportRoute[]) {
    if (!routes) {
        return null;
    }

    const routeGeometries = routes.map((route) => getRouteGeometry({poiId: route.id}));

    try {
        return await Promise.all(routeGeometries);
    } catch (error) {
        notifyBugsnag(error, "route-geometry (numlabs) failed");
        return null;
    }
}

//  Utils
function getUniqueStops(routes: IPublicTransportRouteWithStops[] | null) {
    if (!routes) {
        return [];
    }

    const regionStops = routes.map((route) => route.stops).flat();
    const routeStopsSet = new Set();

    return regionStops.filter((stop) => {
        const isDuplicate = routeStopsSet.has(stop.id);
        routeStopsSet.add(stop.id);

        return !isDuplicate;
    });
}
