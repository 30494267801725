import React from "react";
import {css, Theme} from "@emotion/react";

import {flex, mt, pl, prettyScroll} from "@pg-design/helpers-css";
import {AreaIcon, FloorUpIcon, PriceIcon, RoomsIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {GalleryPicturesGrid} from "@pg-mono/gallery";
import {numberFormat} from "@pg-mono/string-utils";

import {OfferDisplayType} from "../offer/helpers/OfferDisplayType";
import {ISalesOffice} from "../offer/types/ISalesOffice";
import {OfferType} from "../offer/types/OfferType";
import {getPropertyPlanImages} from "../property/helpers/get_property_plan_images";
import {IPropertyListProperty} from "../property/types/PropertyListProperty";
import {PropertyListPlanImage} from "./PropertyListPlanImage";

interface IProps {
    link: string;
    property: IPropertyListProperty;
    offer: {
        id: number;
        configuration: {
            display_type: OfferDisplayType;
            is_autoresponder_enabled: boolean;
            limited_presentation?: boolean;
            phone_clip: string | null;
        };
        geo_point: {
            coordinates: [number, number];
        };
        is_active?: boolean;
        is_phone_clip_enabled: boolean;
        name: string;
        type: OfferType;
        region: {
            id: number;
            full_name: string;
        };
        sales_offices: ISalesOffice[];
        vendor: {
            id: number;
            logo: {
                v_log_120x90: string;
            } | null;
            name: string;
            slug: string;
        };
    };
}

export const PropertyListPlanModalContent: React.FC<IProps> = (props) => {
    const {property, offer} = props;
    const forceShowPrice = property.offer.vendor.configuration.force_show_price;

    const formatFloor = () => {
        if (property && property.floor != null) {
            if (property.floor === 0) {
                return "Parter";
            } else {
                return numberFormat(property.floor);
            }
        }
        return null;
    };

    const onPropertyPlanClick = () => {
        if (property?.plan) {
            window.open(property?.plan, "_blank");
        }
    };

    return (
        <div css={planWrapper}>
            {property.plan_image_pages.length <= 1 ? (
                <PropertyListPlanImage property={property} offerName={offer.name} />
            ) : (
                <div css={propertyPlanGallery}>
                    <GalleryPicturesGrid
                        getImageAlt={(index) => `rzut ${index + 1} w nieruchomości ${property.number} z inwestycji ${offer.name}`}
                        images={getPropertyPlanImages(property.plan_image_pages)}
                        onImageClick={onPropertyPlanClick}
                    />
                </div>
            )}

            <div css={propertyIconsHolder}>
                {property.rooms && (
                    <div css={propertyIconsItem}>
                        <RoomsIcon size="2" fill="#fff" />

                        <div css={propertyIconsItemText}>
                            <Text css={itemName} variant="body_copy_2">
                                Pokoje
                            </Text>
                            <Text variant="body_copy_2" strong>
                                {property.rooms}
                            </Text>
                        </div>
                    </div>
                )}

                {property.area && (
                    <div css={propertyIconsItem}>
                        <AreaIcon size="2" fill="#fff" />

                        <div css={propertyIconsItemText}>
                            <Text css={itemName} variant="body_copy_2">
                                Powierzchnia
                            </Text>
                            <Text variant="body_copy_2" strong>
                                {property.area} m<sup>2</sup>
                            </Text>
                        </div>
                    </div>
                )}

                {offer.type === OfferType.HOUSE
                    ? property.plot_area && (
                          <div css={propertyIconsItem}>
                              <AreaIcon size="2" fill="#fff" />

                              <div css={propertyIconsItemText}>
                                  <Text css={itemName} variant="body_copy_2">
                                      Powierzchnia działki
                                  </Text>
                                  <Text variant="body_copy_2" strong>
                                      {property.plot_area} m<sup>2</sup>
                                  </Text>
                              </div>
                          </div>
                      )
                    : property.floor !== null && (
                          <div css={propertyIconsItem}>
                              <FloorUpIcon size="2" fill="#fff" />

                              <div css={propertyIconsItemText}>
                                  <Text css={itemName} variant="body_copy_2">
                                      Piętro
                                  </Text>
                                  <Text variant="body_copy_2" strong>
                                      {formatFloor()}
                                  </Text>
                              </div>
                          </div>
                      )}

                {forceShowPrice && (
                    <div css={propertyIconsItem}>
                        <PriceIcon size="2" fill="#fff" />

                        <div css={propertyIconsItemText}>
                            <Text css={itemName} variant="body_copy_2">
                                Cena
                            </Text>
                            <Text variant="body_copy_2" strong className="bt">
                                {numberFormat(property.price)} zł
                            </Text>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const propertyPlanGallery = (theme: Theme) => css`
    ${prettyScroll(theme.colors.gray[400], theme.colors.gray[600])};
`;

const planWrapper = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;

    @media (min-width: ${theme.breakpoints.md}) {
        padding: 0 0 8rem 0;
    }
`;

const propertyIconsHolder = (props: Theme) => css`
    flex-grow: 1;
    flex-shrink: 0;
    ${flex("flex-start", "space-between")};
    ${mt(3)};
    color: #fff;

    @media (min-width: ${props.breakpoints.md}) {
        ${mt(4)};
        ${pl(2)};
        justify-content: flex-start;
    }
`;

const propertyIconsItem = (props: Theme) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${props.breakpoints.md}) {
        margin-right: 4rem;
        flex-direction: row;

        > svg {
            transform: scale(3);
        }
    }
`;

const propertyIconsItemText = (props: Theme) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
    margin-left: 0;
    align-items: center;

    @media (min-width: ${props.breakpoints.md}) {
        margin-left: 2rem;
        margin-top: 0;
        align-items: flex-start;
    }
`;

const itemName = (theme: Theme) => css`
    color: ${theme.colors.gray[100]};
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {flex, mt, pl, prettyScroll} from "@pg-design/helpers-css";
import {AreaIcon, FloorUpIcon, PriceIcon, RoomsIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {GalleryPicturesGrid} from "@pg-mono/gallery";
import {numberFormat} from "@pg-mono/string-utils";

import {OfferDisplayType} from "../offer/helpers/OfferDisplayType";
import {ISalesOffice} from "../offer/types/ISalesOffice";
import {OfferType} from "../offer/types/OfferType";
import {getPropertyPlanImages} from "../property/helpers/get_property_plan_images";
import {IPropertyListProperty} from "../property/types/PropertyListProperty";
import {PropertyListPlanImage} from "./PropertyListPlanImage";

interface IProps {
    link: string;
    property: IPropertyListProperty;
    offer: {
        id: number;
        configuration: {
            display_type: OfferDisplayType;
            is_autoresponder_enabled: boolean;
            limited_presentation?: boolean;
            phone_clip: string | null;
        };
        geo_point: {
            coordinates: [number, number];
        };
        is_active?: boolean;
        is_phone_clip_enabled: boolean;
        name: string;
        type: OfferType;
        region: {
            id: number;
            full_name: string;
        };
        sales_offices: ISalesOffice[];
        vendor: {
            id: number;
            logo: {
                v_log_120x90: string;
            } | null;
            name: string;
            slug: string;
        };
    };
}

export const PropertyListPlanModalContent: React.FC<IProps> = (props) => {
    const {property, offer} = props;
    const forceShowPrice = property.offer.vendor.configuration.force_show_price;

    const formatFloor = () => {
        if (property && property.floor != null) {
            if (property.floor === 0) {
                return "Parter";
            } else {
                return numberFormat(property.floor);
            }
        }
        return null;
    };

    const onPropertyPlanClick = () => {
        if (property?.plan) {
            window.open(property?.plan, "_blank");
        }
    };

    return (
        <div css={planWrapper}>
            {property.plan_image_pages.length <= 1 ? (
                <PropertyListPlanImage property={property} offerName={offer.name} />
            ) : (
                <div css={propertyPlanGallery}>
                    <GalleryPicturesGrid
                        getImageAlt={(index) => `rzut ${index + 1} w nieruchomości ${property.number} z inwestycji ${offer.name}`}
                        images={getPropertyPlanImages(property.plan_image_pages)}
                        onImageClick={onPropertyPlanClick}
                    />
                </div>
            )}

            <div css={propertyIconsHolder}>
                {property.rooms && (
                    <div css={propertyIconsItem}>
                        <RoomsIcon size="2" fill="#fff" />

                        <div css={propertyIconsItemText}>
                            <Text css={itemName} variant="body_copy_2">
                                Pokoje
                            </Text>
                            <Text variant="body_copy_2" strong>
                                {property.rooms}
                            </Text>
                        </div>
                    </div>
                )}

                {property.area && (
                    <div css={propertyIconsItem}>
                        <AreaIcon size="2" fill="#fff" />

                        <div css={propertyIconsItemText}>
                            <Text css={itemName} variant="body_copy_2">
                                Powierzchnia
                            </Text>
                            <Text variant="body_copy_2" strong>
                                {property.area} m<sup>2</sup>
                            </Text>
                        </div>
                    </div>
                )}

                {offer.type === OfferType.HOUSE
                    ? property.plot_area && (
                          <div css={propertyIconsItem}>
                              <AreaIcon size="2" fill="#fff" />

                              <div css={propertyIconsItemText}>
                                  <Text css={itemName} variant="body_copy_2">
                                      Powierzchnia działki
                                  </Text>
                                  <Text variant="body_copy_2" strong>
                                      {property.plot_area} m<sup>2</sup>
                                  </Text>
                              </div>
                          </div>
                      )
                    : property.floor !== null && (
                          <div css={propertyIconsItem}>
                              <FloorUpIcon size="2" fill="#fff" />

                              <div css={propertyIconsItemText}>
                                  <Text css={itemName} variant="body_copy_2">
                                      Piętro
                                  </Text>
                                  <Text variant="body_copy_2" strong>
                                      {formatFloor()}
                                  </Text>
                              </div>
                          </div>
                      )}

                {forceShowPrice && (
                    <div css={propertyIconsItem}>
                        <PriceIcon size="2" fill="#fff" />

                        <div css={propertyIconsItemText}>
                            <Text css={itemName} variant="body_copy_2">
                                Cena
                            </Text>
                            <Text variant="body_copy_2" strong className="bt">
                                {numberFormat(property.price)} zł
                            </Text>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const propertyPlanGallery = (theme: Theme) => css`
    ${prettyScroll(theme.colors.gray[400], theme.colors.gray[600])};
`;

const planWrapper = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;

    @media (min-width: ${theme.breakpoints.md}) {
        padding: 0 0 8rem 0;
    }
`;

const propertyIconsHolder = (props: Theme) => css`
    flex-grow: 1;
    flex-shrink: 0;
    ${flex("flex-start", "space-between")};
    ${mt(3)};
    color: #fff;

    @media (min-width: ${props.breakpoints.md}) {
        ${mt(4)};
        ${pl(2)};
        justify-content: flex-start;
    }
`;

const propertyIconsItem = (props: Theme) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${props.breakpoints.md}) {
        margin-right: 4rem;
        flex-direction: row;

        > svg {
            transform: scale(3);
        }
    }
`;

const propertyIconsItemText = (props: Theme) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
    margin-left: 0;
    align-items: center;

    @media (min-width: ${props.breakpoints.md}) {
        margin-left: 2rem;
        margin-top: 0;
        align-items: flex-start;
    }
`;

const itemName = (theme: Theme) => css`
    color: ${theme.colors.gray[100]};
`;
