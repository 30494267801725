import {useState} from "react";
import {Interpolation, Theme} from "@emotion/react";

import {IRange} from "@pg-design/inputs-module";

import {SearchFiltersRooms} from "../../../search/components/filters/SearchFiltersRooms";
import {getRoomLabel} from "../../../search/utils/room_field_data_translations";
import {FakeSelectWithModal} from "./FakeSelectWithModal";

interface IProps {
    initialValue?: IFilterValue;
    rightDropdown?: boolean;
    onConfirm?: (value: IFilterValue) => void;
    handleClear?: () => void;
    aplaCss?: Interpolation<Theme>;
    labelRenderer?: (value: IFilterValue) => Parameters<typeof FakeSelectWithModal>[0]["label"];
    onDropdownOpen?: () => void;
    onDropdownClose?: () => void;
    className?: string;
}

type IFilterValue = IRange<number | "">;
const emptyValue: IFilterValue = {lower: "", upper: ""};

export function RoomFilter(props: IProps) {
    const {initialValue, onConfirm, handleClear, rightDropdown, className} = props;
    const [value, setValue] = useState<IFilterValue>(initialValue ? initialValue : emptyValue);

    const handlers = {
        confirm: (values: IFilterValue) => {
            if (onConfirm) {
                onConfirm(values);
            }
        },
        clear: () => {
            if (handleClear) {
                setValue(emptyValue);
                handleClear();
            }
        }
    };

    return (
        <FakeSelectWithModal
            label={props.labelRenderer ? props.labelRenderer(value) : getRoomLabel(value, "Pokoje")}
            rightDropdown={rightDropdown}
            isEmpty={!initialValue || (initialValue && initialValue.lower === "" && initialValue.upper === "")}
            onClear={handlers.clear}
            onDropdownOpen={props.onDropdownOpen}
            onDropdownClose={props.onDropdownClose}
            aplaCss={props.aplaCss}
            className={className}
        >
            {(props) => (
                <SearchFiltersRooms
                    value={value}
                    name="rooms"
                    onChange={(_, value) => {
                        setValue(value);
                    }}
                    toggleDropdown={() => {
                        props.close();
                    }}
                    onSubmitSingle={() => {
                        handlers.confirm(value);
                        props.close();
                    }}
                    clearField={handlers.clear}
                    showFooter
                />
            )}
        </FakeSelectWithModal>
    );
}
import {useState} from "react";
import {Interpolation, Theme} from "@emotion/react";

import {IRange} from "@pg-design/inputs-module";

import {SearchFiltersRooms} from "../../../search/components/filters/SearchFiltersRooms";
import {getRoomLabel} from "../../../search/utils/room_field_data_translations";
import {FakeSelectWithModal} from "./FakeSelectWithModal";

interface IProps {
    initialValue?: IFilterValue;
    rightDropdown?: boolean;
    onConfirm?: (value: IFilterValue) => void;
    handleClear?: () => void;
    aplaCss?: Interpolation<Theme>;
    labelRenderer?: (value: IFilterValue) => Parameters<typeof FakeSelectWithModal>[0]["label"];
    onDropdownOpen?: () => void;
    onDropdownClose?: () => void;
    className?: string;
}

type IFilterValue = IRange<number | "">;
const emptyValue: IFilterValue = {lower: "", upper: ""};

export function RoomFilter(props: IProps) {
    const {initialValue, onConfirm, handleClear, rightDropdown, className} = props;
    const [value, setValue] = useState<IFilterValue>(initialValue ? initialValue : emptyValue);

    const handlers = {
        confirm: (values: IFilterValue) => {
            if (onConfirm) {
                onConfirm(values);
            }
        },
        clear: () => {
            if (handleClear) {
                setValue(emptyValue);
                handleClear();
            }
        }
    };

    return (
        <FakeSelectWithModal
            label={props.labelRenderer ? props.labelRenderer(value) : getRoomLabel(value, "Pokoje")}
            rightDropdown={rightDropdown}
            isEmpty={!initialValue || (initialValue && initialValue.lower === "" && initialValue.upper === "")}
            onClear={handlers.clear}
            onDropdownOpen={props.onDropdownOpen}
            onDropdownClose={props.onDropdownClose}
            aplaCss={props.aplaCss}
            className={className}
        >
            {(props) => (
                <SearchFiltersRooms
                    value={value}
                    name="rooms"
                    onChange={(_, value) => {
                        setValue(value);
                    }}
                    toggleDropdown={() => {
                        props.close();
                    }}
                    onSubmitSingle={() => {
                        handlers.confirm(value);
                        props.close();
                    }}
                    clearField={handlers.clear}
                    showFooter
                />
            )}
        </FakeSelectWithModal>
    );
}
