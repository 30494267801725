import {Theme} from "@emotion/react";

import {IPolyline} from "../types/IPolyline";

export type IPolylinePresetName = "primary" | "secondaryDashed" | "busLine" | "tramLine" | "trainLine" | "subwayLine";

const getPresets = (theme: Theme): {[key in IPolylinePresetName]: Partial<IPolyline>} => ({
    primary: {
        pathOptions: {
            color: theme.colors.primary,
            weight: 5
        }
    },
    secondaryDashed: {
        pathOptions: {
            color: theme.colors.secondary,
            weight: 3,
            dashArray: "3, 3",
            lineCap: "butt"
        }
    },
    busLine: {
        pathOptions: {
            color: "#0099FF",
            weight: 4,
            dashArray: undefined,
            dashOffset: undefined
        }
    },
    tramLine: {
        pathOptions: {
            color: "#4EB700",
            weight: 4,
            dashArray: undefined,
            dashOffset: undefined
        }
    },
    trainLine: {
        pathOptions: {
            color: theme.colors.secondary,
            weight: 4,
            lineCap: "butt",
            dashArray: "3, 3"
        }
    },
    subwayLine: {
        pathOptions: {
            color: "#E5E006",
            weight: 4,
            lineCap: "butt",
            dashArray: "3, 3"
        }
    }
});
//theme.colors.danger
type IConfigurationParam = Partial<Omit<IPolyline, "id" | "positions">> & {id: IPolyline["id"]; positions: IPolyline["positions"]};

export const createPolyline = (presetName: IPolylinePresetName, configuration: IConfigurationParam) => (theme: Theme) => {
    const presets = getPresets(theme);

    return {
        ...presets[presetName],
        ...configuration
    };
};
import {Theme} from "@emotion/react";

import {IPolyline} from "../types/IPolyline";

export type IPolylinePresetName = "primary" | "secondaryDashed" | "busLine" | "tramLine" | "trainLine" | "subwayLine";

const getPresets = (theme: Theme): {[key in IPolylinePresetName]: Partial<IPolyline>} => ({
    primary: {
        pathOptions: {
            color: theme.colors.primary,
            weight: 5
        }
    },
    secondaryDashed: {
        pathOptions: {
            color: theme.colors.secondary,
            weight: 3,
            dashArray: "3, 3",
            lineCap: "butt"
        }
    },
    busLine: {
        pathOptions: {
            color: "#0099FF",
            weight: 4,
            dashArray: undefined,
            dashOffset: undefined
        }
    },
    tramLine: {
        pathOptions: {
            color: "#4EB700",
            weight: 4,
            dashArray: undefined,
            dashOffset: undefined
        }
    },
    trainLine: {
        pathOptions: {
            color: theme.colors.secondary,
            weight: 4,
            lineCap: "butt",
            dashArray: "3, 3"
        }
    },
    subwayLine: {
        pathOptions: {
            color: "#E5E006",
            weight: 4,
            lineCap: "butt",
            dashArray: "3, 3"
        }
    }
});
//theme.colors.danger
type IConfigurationParam = Partial<Omit<IPolyline, "id" | "positions">> & {id: IPolyline["id"]; positions: IPolyline["positions"]};

export const createPolyline = (presetName: IPolylinePresetName, configuration: IConfigurationParam) => (theme: Theme) => {
    const presets = getPresets(theme);

    return {
        ...presets[presetName],
        ...configuration
    };
};
