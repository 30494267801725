import React from "react";
import styled from "@emotion/styled";

import {Checkbox} from "@pg-design/checkbox-module";

import {FloorChoice} from "../../real_estate/types/FloorChoice";
import {SearchFilterLabel} from "./filters/SearchFilterLabel";

interface IOwnProps {
    error?: string[];
    name: string;
    label?: string;
    id: string;
    onChange: (fieldName: string, value: number[]) => void;
    value: number[];
}

export const FloorChoicesSwitch = (props: IOwnProps) => {
    const onOptionSelect = (name: string) => {
        const selectedOptionValue = floorOptions.find((el) => el.label === name)?.value;
        if (!selectedOptionValue) {
            return;
        }
        const valueSelected = props.value.includes(selectedOptionValue);

        if (!valueSelected) {
            props.onChange(props.name, [...props.value, selectedOptionValue]);
        }

        if (valueSelected) {
            const filtered = props.value.filter((floorChoiceValue: number) => floorChoiceValue !== selectedOptionValue);
            props.onChange(props.name, [...filtered]);
        }
    };

    return (
        <>
            {props.label ? <SearchFilterLabel htmlFor={props.id} label={props.label} /> : null}
            <FloorChoicesHolder>
                {floorOptions.map((floorOption) => {
                    const check = props.value.includes(floorOption.value);
                    return (
                        <Checkbox
                            id={"rooms_option_" + floorOption.value}
                            key={floorOption.value}
                            labelContent={floorOption.label}
                            name={floorOption.label}
                            checked={check}
                            value={floorOption.value}
                            onChange={onOptionSelect}
                        />
                    );
                })}
            </FloorChoicesHolder>
        </>
    );
};
export const floorOptions = [
    {label: "Parter", value: FloorChoice.GROUND},
    {label: "Parter z ogródkiem", value: FloorChoice.GROUND_WITH_GARDEN},
    {label: "Niskie piętra (1-4)", value: FloorChoice.LOW_LEVELS},
    {label: "Wysokie piętra (5+)", value: FloorChoice.HIGH_LEVELS},
    {label: "Ostatnie piętro", value: FloorChoice.LAST_LEVEL},
    {label: "Ostatnie piętro z tarasem", value: FloorChoice.LAST_LEVEL_WITH_TERRACE}
];

const FloorChoicesHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    flex-shrink: 0;
    flex-grow: 0;

    > div {
        margin-bottom: 1.5rem;
    }
`;
import React from "react";
import styled from "@emotion/styled";

import {Checkbox} from "@pg-design/checkbox-module";

import {FloorChoice} from "../../real_estate/types/FloorChoice";
import {SearchFilterLabel} from "./filters/SearchFilterLabel";

interface IOwnProps {
    error?: string[];
    name: string;
    label?: string;
    id: string;
    onChange: (fieldName: string, value: number[]) => void;
    value: number[];
}

export const FloorChoicesSwitch = (props: IOwnProps) => {
    const onOptionSelect = (name: string) => {
        const selectedOptionValue = floorOptions.find((el) => el.label === name)?.value;
        if (!selectedOptionValue) {
            return;
        }
        const valueSelected = props.value.includes(selectedOptionValue);

        if (!valueSelected) {
            props.onChange(props.name, [...props.value, selectedOptionValue]);
        }

        if (valueSelected) {
            const filtered = props.value.filter((floorChoiceValue: number) => floorChoiceValue !== selectedOptionValue);
            props.onChange(props.name, [...filtered]);
        }
    };

    return (
        <>
            {props.label ? <SearchFilterLabel htmlFor={props.id} label={props.label} /> : null}
            <FloorChoicesHolder>
                {floorOptions.map((floorOption) => {
                    const check = props.value.includes(floorOption.value);
                    return (
                        <Checkbox
                            id={"rooms_option_" + floorOption.value}
                            key={floorOption.value}
                            labelContent={floorOption.label}
                            name={floorOption.label}
                            checked={check}
                            value={floorOption.value}
                            onChange={onOptionSelect}
                        />
                    );
                })}
            </FloorChoicesHolder>
        </>
    );
};
export const floorOptions = [
    {label: "Parter", value: FloorChoice.GROUND},
    {label: "Parter z ogródkiem", value: FloorChoice.GROUND_WITH_GARDEN},
    {label: "Niskie piętra (1-4)", value: FloorChoice.LOW_LEVELS},
    {label: "Wysokie piętra (5+)", value: FloorChoice.HIGH_LEVELS},
    {label: "Ostatnie piętro", value: FloorChoice.LAST_LEVEL},
    {label: "Ostatnie piętro z tarasem", value: FloorChoice.LAST_LEVEL_WITH_TERRACE}
];

const FloorChoicesHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    flex-shrink: 0;
    flex-grow: 0;

    > div {
        margin-bottom: 1.5rem;
    }
`;
