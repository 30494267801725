import {ReactNode} from "react";
import {css, Theme} from "@emotion/react";

import {m} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader-module";

import {GalleryHolderPropertyPlanButton} from "../../offer/components/gallery_holder/GalleryHolderPropertyPlanButton";
import {IPropertyWithPossiblePlan, useSimhomeProperty} from "../hooks/use_simhome_property";
import {IPropertyPlanVendor, PropertyPlan} from "./property_plan/PropertyPlan";

interface IProps {
    vendor: IPropertyPlanVendor;
    property: IPropertyWithPossiblePlan;
    onPlanDownloadClick: () => void;
    legacyPlan: ReactNode;
    className?: string;
}

export function PropertyPlanDisplay(props: IProps) {
    const {vendor, property, onPlanDownloadClick, legacyPlan, className} = props;
    const {simhomeProperty, isLoading, showExternalPlan, showLegacyPlan, rpPropertyWithExternalPlan} = useSimhomeProperty({
        rpProperty: property
    });

    return (
        <div css={planWrap} className={className}>
            {isLoading && (
                <div css={loaderWrap}>
                    <Loader size="lg" />
                </div>
            )}

            {!isLoading && showLegacyPlan && <>{legacyPlan}</>}

            {showExternalPlan && rpPropertyWithExternalPlan && simhomeProperty && (
                <PropertyPlan vendor={vendor} property={rpPropertyWithExternalPlan} simhomeProperty={simhomeProperty} />
            )}

            {property.plan && (showExternalPlan || showLegacyPlan) && (
                <div css={propertyPlanButtonHolder}>
                    <GalleryHolderPropertyPlanButton onPlanButtonClick={onPlanDownloadClick} propertyPlan={property.plan} />
                </div>
            )}
        </div>
    );
}

//  Styles
const loaderWrap = css`
    background: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const planWrap = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    color: ${theme.colors.secondary};
`;

const propertyPlanButtonHolder = (theme: Theme) => css`
    ${m(1, 0)};

    @media (min-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;
import {ReactNode} from "react";
import {css, Theme} from "@emotion/react";

import {m} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader-module";

import {GalleryHolderPropertyPlanButton} from "../../offer/components/gallery_holder/GalleryHolderPropertyPlanButton";
import {IPropertyWithPossiblePlan, useSimhomeProperty} from "../hooks/use_simhome_property";
import {IPropertyPlanVendor, PropertyPlan} from "./property_plan/PropertyPlan";

interface IProps {
    vendor: IPropertyPlanVendor;
    property: IPropertyWithPossiblePlan;
    onPlanDownloadClick: () => void;
    legacyPlan: ReactNode;
    className?: string;
}

export function PropertyPlanDisplay(props: IProps) {
    const {vendor, property, onPlanDownloadClick, legacyPlan, className} = props;
    const {simhomeProperty, isLoading, showExternalPlan, showLegacyPlan, rpPropertyWithExternalPlan} = useSimhomeProperty({
        rpProperty: property
    });

    return (
        <div css={planWrap} className={className}>
            {isLoading && (
                <div css={loaderWrap}>
                    <Loader size="lg" />
                </div>
            )}

            {!isLoading && showLegacyPlan && <>{legacyPlan}</>}

            {showExternalPlan && rpPropertyWithExternalPlan && simhomeProperty && (
                <PropertyPlan vendor={vendor} property={rpPropertyWithExternalPlan} simhomeProperty={simhomeProperty} />
            )}

            {property.plan && (showExternalPlan || showLegacyPlan) && (
                <div css={propertyPlanButtonHolder}>
                    <GalleryHolderPropertyPlanButton onPlanButtonClick={onPlanDownloadClick} propertyPlan={property.plan} />
                </div>
            )}
        </div>
    );
}

//  Styles
const loaderWrap = css`
    background: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const planWrap = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    color: ${theme.colors.secondary};
`;

const propertyPlanButtonHolder = (theme: Theme) => css`
    ${m(1, 0)};

    @media (min-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;
