export type ISimhomeRoomTypeName = "room" | "kitchen" | "bathroom" | "other";

export function getSimhomeRoomTypeName(roomType: number | null): ISimhomeRoomTypeName {
    if (typeof roomType === "number" && roomType === 0) {
        return "room";
    }

    if (roomType === 1) {
        return "kitchen";
    }

    if (roomType === 2) {
        return "bathroom";
    }

    return "other";
}
export type ISimhomeRoomTypeName = "room" | "kitchen" | "bathroom" | "other";

export function getSimhomeRoomTypeName(roomType: number | null): ISimhomeRoomTypeName {
    if (typeof roomType === "number" && roomType === 0) {
        return "room";
    }

    if (roomType === 1) {
        return "kitchen";
    }

    if (roomType === 2) {
        return "bathroom";
    }

    return "other";
}
