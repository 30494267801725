import type {LatLngExpression, LatLngLiteral} from "leaflet";

import {convertToLatLngLiteral} from "./convert_to_lat_lng_literal";
import {convertToLatLngLiteralOfPoland} from "./convert_to_lat_lng_literal_of_poland";

interface IOptions {
    reversedValues?: boolean;
    coordsOfPoland?: boolean;
}

const isLastLevel = (values: number[] | number[][] | number[][][]): values is number[] => {
    return typeof values[0] === "number" && typeof values[1] === "number";
};

export const convertToArrayOfLatLngLiterals = (
    values: number[][] | number[][][] | number[][][][],
    options: IOptions = {}
): LatLngExpression[] | LatLngExpression[][] | LatLngExpression[][][] => {
    return values.map((value) => {
        if (isLastLevel(value)) {
            return options.coordsOfPoland ? convertToLatLngLiteralOfPoland(value) : convertToLatLngLiteral(value, options);
        }

        return convertToArrayOfLatLngLiterals(value, options);
    }) as LatLngLiteral[] | LatLngLiteral[][] | LatLngLiteral[][][];
};
import type {LatLngExpression, LatLngLiteral} from "leaflet";

import {convertToLatLngLiteral} from "./convert_to_lat_lng_literal";
import {convertToLatLngLiteralOfPoland} from "./convert_to_lat_lng_literal_of_poland";

interface IOptions {
    reversedValues?: boolean;
    coordsOfPoland?: boolean;
}

const isLastLevel = (values: number[] | number[][] | number[][][]): values is number[] => {
    return typeof values[0] === "number" && typeof values[1] === "number";
};

export const convertToArrayOfLatLngLiterals = (
    values: number[][] | number[][][] | number[][][][],
    options: IOptions = {}
): LatLngExpression[] | LatLngExpression[][] | LatLngExpression[][][] => {
    return values.map((value) => {
        if (isLastLevel(value)) {
            return options.coordsOfPoland ? convertToLatLngLiteralOfPoland(value) : convertToLatLngLiteral(value, options);
        }

        return convertToArrayOfLatLngLiterals(value, options);
    }) as LatLngLiteral[] | LatLngLiteral[][] | LatLngLiteral[][][];
};
