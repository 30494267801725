import {useEffect} from "react";
import {useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {flexAbsoluteCenter, mr} from "@pg-design/helpers-css";
import {PhoneIcon} from "@pg-design/icons-module";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";
import {phoneNumberFormat} from "@pg-mono/string-utils";

import {NO_CONTACT_DATA} from "../application/components/ApplicationPhoneNumber";
import {useClipPhoneNumber} from "../application/hooks/use_clip_phone_number";

interface IProps {
    fallbackNumber?: string;
    onShowPhoneNumber?: () => void;
    onClickPhoneNumber?: () => void;
    text: string;
    propertyId?: number;
    className?: string;
    fetchOnMount?: boolean;
}

export const ClipPhoneButton = (props: IProps) => {
    const {phoneNumber, fetchClipPhoneNumber, isFetching} = useClipPhoneNumber(props.fallbackNumber);
    const theme = useTheme();

    const onClick = () => {
        fetchClipPhoneNumber(props.propertyId);
        props.onShowPhoneNumber?.();
    };

    const onClickPhoneNumber = () => {
        props.onClickPhoneNumber?.();
    };

    useEffect(() => {
        if (props.fetchOnMount) {
            fetchClipPhoneNumber(props.propertyId);
        }
    }, []);

    return !phoneNumber ? (
        <div className={props.className}>
            <Button onClick={onClick} type="button" variant="outlined_secondary" css={flexAbsoluteCenter}>
                <PhoneIcon size="2.4" wrapperSize="4" css={mr(2)} />
                {props.text}
            </Button>
        </div>
    ) : isFetching ? (
        <div className={props.className}>
            <Loader size="sm" />
        </div>
    ) : phoneNumber ? (
        <Button
            variant="filled_primary"
            href={!!phoneNumber ? `tel:+${phoneNumber.replace(/ /g, "")}` : undefined}
            onClick={onClickPhoneNumber}
            className={props.className}
        >
            {!!phoneNumber ? phoneNumberFormat(phoneNumber) : "BRAK DANYCH KONTAKTOWYCH"}
        </Button>
    ) : (
        <Text as="span" variant="headline_6" align="center" color={theme.colors.secondary} className={props.className}>
            {NO_CONTACT_DATA}
        </Text>
    );
};
import {useEffect} from "react";
import {useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {flexAbsoluteCenter, mr} from "@pg-design/helpers-css";
import {PhoneIcon} from "@pg-design/icons-module";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";
import {phoneNumberFormat} from "@pg-mono/string-utils";

import {NO_CONTACT_DATA} from "../application/components/ApplicationPhoneNumber";
import {useClipPhoneNumber} from "../application/hooks/use_clip_phone_number";

interface IProps {
    fallbackNumber?: string;
    onShowPhoneNumber?: () => void;
    onClickPhoneNumber?: () => void;
    text: string;
    propertyId?: number;
    className?: string;
    fetchOnMount?: boolean;
}

export const ClipPhoneButton = (props: IProps) => {
    const {phoneNumber, fetchClipPhoneNumber, isFetching} = useClipPhoneNumber(props.fallbackNumber);
    const theme = useTheme();

    const onClick = () => {
        fetchClipPhoneNumber(props.propertyId);
        props.onShowPhoneNumber?.();
    };

    const onClickPhoneNumber = () => {
        props.onClickPhoneNumber?.();
    };

    useEffect(() => {
        if (props.fetchOnMount) {
            fetchClipPhoneNumber(props.propertyId);
        }
    }, []);

    return !phoneNumber ? (
        <div className={props.className}>
            <Button onClick={onClick} type="button" variant="outlined_secondary" css={flexAbsoluteCenter}>
                <PhoneIcon size="2.4" wrapperSize="4" css={mr(2)} />
                {props.text}
            </Button>
        </div>
    ) : isFetching ? (
        <div className={props.className}>
            <Loader size="sm" />
        </div>
    ) : phoneNumber ? (
        <Button
            variant="filled_primary"
            href={!!phoneNumber ? `tel:+${phoneNumber.replace(/ /g, "")}` : undefined}
            onClick={onClickPhoneNumber}
            className={props.className}
        >
            {!!phoneNumber ? phoneNumberFormat(phoneNumber) : "BRAK DANYCH KONTAKTOWYCH"}
        </Button>
    ) : (
        <Text as="span" variant="headline_6" align="center" color={theme.colors.secondary} className={props.className}>
            {NO_CONTACT_DATA}
        </Text>
    );
};
