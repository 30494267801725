import {batch, useDispatch, useSelector} from "react-redux";

import {getFavouriteObject} from "@pg-mono/favourites";
import {RequestState} from "@pg-mono/request-state";

import {IRPStore} from "../../app/rp_reducer";
import {addToFavourites, removeFromFavourites} from "../actions/manage_favourites";
import {
    addOfferToFavouritesSuccess,
    addPropertyToFavouritesSuccess,
    removeOfferFromFavouritesSuccess,
    removePropertyFromFavouritesSuccess
} from "../utils/alerts";

export const useFavourites = () => {
    const dispatch = useDispatch();

    const favouriteOffers = useSelector((state: IRPStore) => state.favourite.favourites.offers);
    const favouriteProperties = useSelector((state: IRPStore) => state.favourite.favourites.properties);

    const favourites = useSelector((state: IRPStore) => state.favourite.favourites);
    const fetchRequestState = useSelector((state: IRPStore) => state.favourite.fetchRequest);
    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);

    //  In case user isn't authenticated, favourites are taken from localStorage
    const isLoaded = isAuthenticated ? fetchRequestState === RequestState.Success || fetchRequestState === RequestState.Error : favourites.initialized;

    return {
        favouritePropertiesIds: favouriteProperties,
        favouriteOfferIds: favouriteOffers,
        loaded: isLoaded,
        fetchRequest: fetchRequestState,
        isOfferInFavourites: (offerId: number) => {
            if (fetchRequestState === RequestState.Waiting || fetchRequestState === RequestState.Error) {
                return false;
            }

            return favouriteOffers.includes(offerId);
        },
        isPropertyInFavourites: (propertyId: number) => {
            if (fetchRequestState === RequestState.Waiting || fetchRequestState === RequestState.Error) {
                return false;
            }

            return favouriteProperties.includes(propertyId);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        addPropertyToFavourites: async (propertyId: number, offerId: number) => {
            const favourite = getFavouriteObject({propertyId});

            batch(() => {
                dispatch(addToFavourites([favourite]));
                dispatch(addPropertyToFavouritesSuccess);
            });
        },
        removePropertyFromFavourites: async (propertyId: number) => {
            const favourite = getFavouriteObject({propertyId});

            batch(() => {
                dispatch(removeFromFavourites([favourite]));
                dispatch(removePropertyFromFavouritesSuccess);
            });
        },
        addOfferToFavourites: async (offerId: number) => {
            const favourite = getFavouriteObject({offerId});

            batch(() => {
                dispatch(addToFavourites([favourite]));
                dispatch(addOfferToFavouritesSuccess);
            });
        },
        removeOfferFromFavourites: async (offerId: number) => {
            const favourite = getFavouriteObject({offerId});

            batch(() => {
                dispatch(removeFromFavourites([favourite]));
                dispatch(removeOfferFromFavouritesSuccess);
            });
        }
    };
};
import {batch, useDispatch, useSelector} from "react-redux";

import {getFavouriteObject} from "@pg-mono/favourites";
import {RequestState} from "@pg-mono/request-state";

import {IRPStore} from "../../app/rp_reducer";
import {addToFavourites, removeFromFavourites} from "../actions/manage_favourites";
import {
    addOfferToFavouritesSuccess,
    addPropertyToFavouritesSuccess,
    removeOfferFromFavouritesSuccess,
    removePropertyFromFavouritesSuccess
} from "../utils/alerts";

export const useFavourites = () => {
    const dispatch = useDispatch();

    const favouriteOffers = useSelector((state: IRPStore) => state.favourite.favourites.offers);
    const favouriteProperties = useSelector((state: IRPStore) => state.favourite.favourites.properties);

    const favourites = useSelector((state: IRPStore) => state.favourite.favourites);
    const fetchRequestState = useSelector((state: IRPStore) => state.favourite.fetchRequest);
    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);

    //  In case user isn't authenticated, favourites are taken from localStorage
    const isLoaded = isAuthenticated ? fetchRequestState === RequestState.Success || fetchRequestState === RequestState.Error : favourites.initialized;

    return {
        favouritePropertiesIds: favouriteProperties,
        favouriteOfferIds: favouriteOffers,
        loaded: isLoaded,
        fetchRequest: fetchRequestState,
        isOfferInFavourites: (offerId: number) => {
            if (fetchRequestState === RequestState.Waiting || fetchRequestState === RequestState.Error) {
                return false;
            }

            return favouriteOffers.includes(offerId);
        },
        isPropertyInFavourites: (propertyId: number) => {
            if (fetchRequestState === RequestState.Waiting || fetchRequestState === RequestState.Error) {
                return false;
            }

            return favouriteProperties.includes(propertyId);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        addPropertyToFavourites: async (propertyId: number, offerId: number) => {
            const favourite = getFavouriteObject({propertyId});

            batch(() => {
                dispatch(addToFavourites([favourite]));
                dispatch(addPropertyToFavouritesSuccess);
            });
        },
        removePropertyFromFavourites: async (propertyId: number) => {
            const favourite = getFavouriteObject({propertyId});

            batch(() => {
                dispatch(removeFromFavourites([favourite]));
                dispatch(removePropertyFromFavouritesSuccess);
            });
        },
        addOfferToFavourites: async (offerId: number) => {
            const favourite = getFavouriteObject({offerId});

            batch(() => {
                dispatch(addToFavourites([favourite]));
                dispatch(addOfferToFavouritesSuccess);
            });
        },
        removeOfferFromFavourites: async (offerId: number) => {
            const favourite = getFavouriteObject({offerId});

            batch(() => {
                dispatch(removeFromFavourites([favourite]));
                dispatch(removeOfferFromFavouritesSuccess);
            });
        }
    };
};
