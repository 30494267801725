import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {postUserPreferences} from "../actions/post_user_preferences";
import {IUserPreferences} from "../interfaces/IUserPreferences";
import {transformUserPreferencesForSave} from "../utils/transform_user_preferences";

interface IOptions {
    debounce: boolean;
}

export const useUserPreferences = (options?: IOptions) => {
    const dispatch = useDispatch();
    const userPreferences = useSelector((state: IRPStore) => state.user.preferences);

    const saveUserPreferences = useCallback(
        (preferences: IUserPreferences) => {
            if (JSON.stringify(preferences) === JSON.stringify(userPreferences)) {
                // Preferences are the same, there is no need to be saved again.
                return Promise.resolve(true);
            }

            return dispatch(postUserPreferences(transformUserPreferencesForSave(preferences), options?.debounce));
        },
        [dispatch]
    );

    return {
        saveUserPreferences,
        userPreferences
    };
};
import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {postUserPreferences} from "../actions/post_user_preferences";
import {IUserPreferences} from "../interfaces/IUserPreferences";
import {transformUserPreferencesForSave} from "../utils/transform_user_preferences";

interface IOptions {
    debounce: boolean;
}

export const useUserPreferences = (options?: IOptions) => {
    const dispatch = useDispatch();
    const userPreferences = useSelector((state: IRPStore) => state.user.preferences);

    const saveUserPreferences = useCallback(
        (preferences: IUserPreferences) => {
            if (JSON.stringify(preferences) === JSON.stringify(userPreferences)) {
                // Preferences are the same, there is no need to be saved again.
                return Promise.resolve(true);
            }

            return dispatch(postUserPreferences(transformUserPreferencesForSave(preferences), options?.debounce));
        },
        [dispatch]
    );

    return {
        saveUserPreferences,
        userPreferences
    };
};
