import {useEffect, useState} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

interface IProps {
    floorSvgUrl: string;
    propertyUuid: string;
    className?: string;
}

export function PropertyFloorImage(props: IProps) {
    const {floorSvgUrl, propertyUuid, className} = props;
    const [svgXml, setSvgXml] = useState<string | null>(null);

    useEffect(() => {
        fetch(floorSvgUrl)
            .then((res) => res.text())
            .then((svgXml) => setSvgXml(svgXml));
    }, []);

    return (
        <FloorImage uuid={propertyUuid} className={className}>
            {svgXml && <div css={svgWrap} dangerouslySetInnerHTML={{__html: svgXml}} />}
        </FloorImage>
    );
}

//  Styles
const FloorImage = styled.div<{uuid: string}>`
    ${({theme, uuid}) => css`
        & svg {
            max-width: 100%;
            max-height: 100%;

            g[data-uuid="${uuid}"] {
                g[data-type="area"] {
                    polygon {
                        fill: ${theme.colors.primary};
                    }
                }
            }
        }
    `}
`;

const svgWrap = css`
    max-height: 100%;
    max-width: 100%;
`;
import {useEffect, useState} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

interface IProps {
    floorSvgUrl: string;
    propertyUuid: string;
    className?: string;
}

export function PropertyFloorImage(props: IProps) {
    const {floorSvgUrl, propertyUuid, className} = props;
    const [svgXml, setSvgXml] = useState<string | null>(null);

    useEffect(() => {
        fetch(floorSvgUrl)
            .then((res) => res.text())
            .then((svgXml) => setSvgXml(svgXml));
    }, []);

    return (
        <FloorImage uuid={propertyUuid} className={className}>
            {svgXml && <div css={svgWrap} dangerouslySetInnerHTML={{__html: svgXml}} />}
        </FloorImage>
    );
}

//  Styles
const FloorImage = styled.div<{uuid: string}>`
    ${({theme, uuid}) => css`
        & svg {
            max-width: 100%;
            max-height: 100%;

            g[data-uuid="${uuid}"] {
                g[data-type="area"] {
                    polygon {
                        fill: ${theme.colors.primary};
                    }
                }
            }
        }
    `}
`;

const svgWrap = css`
    max-height: 100%;
    max-width: 100%;
`;
