import {ReactNode} from "react";

import {convertToCountryLatLngLiteral, IMarker, PoiType} from "@pg-mono/open-street-map";

import {IPublicTransportStop} from "../types/IPublicTransportStop";
import {IPublicTransportType} from "../types/IPublicTransportType";
import {getPassiveTransportStopIconUrl} from "./get_passive_transport_stop_icon_url";
import {TransportPoiType} from "./PoiType";

const poiSubTypeMap = {
    [IPublicTransportType.BUS]: TransportPoiType.BUS,
    [IPublicTransportType.TRAM]: TransportPoiType.TRAM,
    [IPublicTransportType.TRAIN]: TransportPoiType.RAILWAY,
    [IPublicTransportType.SUBWAY]: TransportPoiType.SUBWAY
};

export function getMapPassiveTransportStopMarker(
    stop: IPublicTransportStop,
    routeType: IPublicTransportType,
    nearestStop: IPublicTransportStop | null,
    popup?: () => ReactNode
): IMarker {
    return {
        id: `m-${stop.coordinates[0]}-${stop.coordinates[1]}`,
        coords: convertToCountryLatLngLiteral(stop.coordinates), // for now, we have transport routes only for Poland
        zIndexOffset: 0,
        popupShowCloseButton: false,
        popup,
        skipInFitBounds: stop.id !== nearestStop?.id,
        icon: {
            url: getPassiveTransportStopIconUrl(routeType),
            sizes: [24, 24]
        },
        poiType: PoiType.TRANSPORT,
        poiSubType: poiSubTypeMap[routeType]
    };
}
import {ReactNode} from "react";

import {convertToCountryLatLngLiteral, IMarker, PoiType} from "@pg-mono/open-street-map";

import {IPublicTransportStop} from "../types/IPublicTransportStop";
import {IPublicTransportType} from "../types/IPublicTransportType";
import {getPassiveTransportStopIconUrl} from "./get_passive_transport_stop_icon_url";
import {TransportPoiType} from "./PoiType";

const poiSubTypeMap = {
    [IPublicTransportType.BUS]: TransportPoiType.BUS,
    [IPublicTransportType.TRAM]: TransportPoiType.TRAM,
    [IPublicTransportType.TRAIN]: TransportPoiType.RAILWAY,
    [IPublicTransportType.SUBWAY]: TransportPoiType.SUBWAY
};

export function getMapPassiveTransportStopMarker(
    stop: IPublicTransportStop,
    routeType: IPublicTransportType,
    nearestStop: IPublicTransportStop | null,
    popup?: () => ReactNode
): IMarker {
    return {
        id: `m-${stop.coordinates[0]}-${stop.coordinates[1]}`,
        coords: convertToCountryLatLngLiteral(stop.coordinates), // for now, we have transport routes only for Poland
        zIndexOffset: 0,
        popupShowCloseButton: false,
        popup,
        skipInFitBounds: stop.id !== nearestStop?.id,
        icon: {
            url: getPassiveTransportStopIconUrl(routeType),
            sizes: [24, 24]
        },
        poiType: PoiType.TRANSPORT,
        poiSubType: poiSubTypeMap[routeType]
    };
}
