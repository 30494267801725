import React, {ReactNode, useState} from "react";
import {css, Interpolation, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {Apla} from "@pg-design/apla-module";
import {borderRadius, calculateRemSize as crs, fadeInAnimation, p} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons";
import {ChevronDownIcon} from "@pg-design/icons";
import {InputWrapper, inputWrapperHorizontalPaddingSize} from "@pg-design/inputs";
import {Text} from "@pg-design/text";
import {useUserDevice} from "@pg-mono/user-device";

interface IProps {
    label: string | ReactNode;
    children: (props: IChildrenProps) => JSX.Element;
    disabled?: boolean;
    isEmpty?: boolean;
    rightDropdown?: boolean;
    onClear?: () => void;
    aplaCss?: Interpolation<Theme>;
    onDropdownOpen?: () => void;
    onDropdownClose?: () => void;
    className?: string;
}

interface IChildrenProps {
    close: () => void;
}

export function FakeSelectWithModal(props: IProps) {
    const {label, disabled, isEmpty, rightDropdown, className, children, onClear} = props;

    const {isMobile} = useUserDevice();
    const [isOpened, setIsOpened] = useState(false);

    const handleDropdownOpen = () => {
        setIsOpened(true);
        props.onDropdownOpen?.();
    };

    const handleDropdownClose = () => {
        setIsOpened(false);
        props.onDropdownClose?.();
    };

    const handlers = {
        keyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === "Escape") {
                handleDropdownClose();
            }

            if (e.key === "Enter") {
                handleDropdownClose();
            }
        },
        upArrowClick: (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();

            handleDropdownClose();
        },
        open: () => {
            handleDropdownOpen();
        },
        close: () => {
            handleDropdownClose();
        },
        clear: (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            if (onClear) {
                onClear();
            }
        }
    };

    return (
        <>
            <InputWrapper
                className={className}
                css={fieldWrap}
                inputWrapCss={inputWrap}
                onClick={(e) => {
                    if (!disabled && isOpened) {
                        handlers.upArrowClick(e);
                    }

                    if (!disabled && !isOpened) {
                        handlers.open();
                    }
                }}
            >
                <div css={filterHolder} onKeyDown={handlers.keyDown}>
                    <div tabIndex={-1} css={filterLabel}>
                        <Text variant="body_copy_2" css={filterLabelText}>
                            {label}
                        </Text>

                        {!isEmpty ? (
                            <div onClick={handlers.clear}>
                                <CloseIcon size="1.4" />
                            </div>
                        ) : (
                            <SearchDropdownArrow isDown={!isOpened} />
                        )}
                    </div>
                    {(isOpened || isMobile) && (
                        <ChildHolder
                            rightDropdown={rightDropdown}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            {children({
                                close: handlers.close
                            })}
                        </ChildHolder>
                    )}
                </div>
            </InputWrapper>

            {isOpened && (
                <Apla
                    applyOn={isOpened}
                    css={[aplaStyle, props.aplaCss]}
                    onClick={() => {
                        handlers.close();
                    }}
                />
            )}
        </>
    );
}

/*
    Sub-components
 */

interface ISearchDropdownArrowProps {
    isDown: boolean;
}

export const SearchDropdownArrow = (props: ISearchDropdownArrowProps) => {
    return (
        <div css={!props.isDown && rotatedStyle}>
            <ChevronDownIcon size="2" />
        </div>
    );
};

const rotatedStyle = css`
    transform: rotate(180deg);
`;

/*
    Styles
 */
const fieldWrap = css`
    display: flex;
    height: ${crs(6)};
    ${p(0, inputWrapperHorizontalPaddingSize)};
    align-items: center;
    cursor: pointer;
`;

const inputWrap = css`
    min-width: 0;
`;

const filterHolder = (theme: Theme) => css`
    position: relative;
    width: 100%;

    @media (max-width: calc(${theme.breakpoints.md} - 1px)) {
        padding: 0;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
`;

const filterLabel = (theme: Theme) => css`
    display: none;

    &:focus {
        outline: none;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
`;

const filterLabelText = css`
    padding-right: 1rem;
    min-width: 0;
`;

interface IChildHolderProps {
    rightDropdown?: boolean;
}

const ChildHolder = styled.div<IChildHolderProps>`
    ${({theme, rightDropdown}) => css`
        background: #fff;
        padding: 1rem 2rem;

        .range-input-lower,
        .range-input-upper {
            margin-bottom: 10px;
        }

        @media (min-width: ${theme.breakpoints.md}) {
            ${fadeInAnimation("0.2s")};

            position: absolute;
            top: 6rem;
            z-index: 1001;
            right: ${rightDropdown ? 0 : "unset"};
            left: ${rightDropdown ? "unset" : 0};

            padding: 2rem 3rem;
            ${borderRadius(4)};
            min-width: 36rem;
            max-height: 60rem;
        }
    `}
`;

const aplaStyle = css`
    position: fixed;
    inset: 0;
    z-index: 50;
    ${borderRadius(2)};
    overflow: hidden;
`;
import React, {ReactNode, useState} from "react";
import {css, Interpolation, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {Apla} from "@pg-design/apla-module";
import {borderRadius, calculateRemSize as crs, fadeInAnimation, p} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons";
import {ChevronDownIcon} from "@pg-design/icons";
import {InputWrapper, inputWrapperHorizontalPaddingSize} from "@pg-design/inputs";
import {Text} from "@pg-design/text";
import {useUserDevice} from "@pg-mono/user-device";

interface IProps {
    label: string | ReactNode;
    children: (props: IChildrenProps) => JSX.Element;
    disabled?: boolean;
    isEmpty?: boolean;
    rightDropdown?: boolean;
    onClear?: () => void;
    aplaCss?: Interpolation<Theme>;
    onDropdownOpen?: () => void;
    onDropdownClose?: () => void;
    className?: string;
}

interface IChildrenProps {
    close: () => void;
}

export function FakeSelectWithModal(props: IProps) {
    const {label, disabled, isEmpty, rightDropdown, className, children, onClear} = props;

    const {isMobile} = useUserDevice();
    const [isOpened, setIsOpened] = useState(false);

    const handleDropdownOpen = () => {
        setIsOpened(true);
        props.onDropdownOpen?.();
    };

    const handleDropdownClose = () => {
        setIsOpened(false);
        props.onDropdownClose?.();
    };

    const handlers = {
        keyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === "Escape") {
                handleDropdownClose();
            }

            if (e.key === "Enter") {
                handleDropdownClose();
            }
        },
        upArrowClick: (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();

            handleDropdownClose();
        },
        open: () => {
            handleDropdownOpen();
        },
        close: () => {
            handleDropdownClose();
        },
        clear: (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            if (onClear) {
                onClear();
            }
        }
    };

    return (
        <>
            <InputWrapper
                className={className}
                css={fieldWrap}
                inputWrapCss={inputWrap}
                onClick={(e) => {
                    if (!disabled && isOpened) {
                        handlers.upArrowClick(e);
                    }

                    if (!disabled && !isOpened) {
                        handlers.open();
                    }
                }}
            >
                <div css={filterHolder} onKeyDown={handlers.keyDown}>
                    <div tabIndex={-1} css={filterLabel}>
                        <Text variant="body_copy_2" css={filterLabelText}>
                            {label}
                        </Text>

                        {!isEmpty ? (
                            <div onClick={handlers.clear}>
                                <CloseIcon size="1.4" />
                            </div>
                        ) : (
                            <SearchDropdownArrow isDown={!isOpened} />
                        )}
                    </div>
                    {(isOpened || isMobile) && (
                        <ChildHolder
                            rightDropdown={rightDropdown}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            {children({
                                close: handlers.close
                            })}
                        </ChildHolder>
                    )}
                </div>
            </InputWrapper>

            {isOpened && (
                <Apla
                    applyOn={isOpened}
                    css={[aplaStyle, props.aplaCss]}
                    onClick={() => {
                        handlers.close();
                    }}
                />
            )}
        </>
    );
}

/*
    Sub-components
 */

interface ISearchDropdownArrowProps {
    isDown: boolean;
}

export const SearchDropdownArrow = (props: ISearchDropdownArrowProps) => {
    return (
        <div css={!props.isDown && rotatedStyle}>
            <ChevronDownIcon size="2" />
        </div>
    );
};

const rotatedStyle = css`
    transform: rotate(180deg);
`;

/*
    Styles
 */
const fieldWrap = css`
    display: flex;
    height: ${crs(6)};
    ${p(0, inputWrapperHorizontalPaddingSize)};
    align-items: center;
    cursor: pointer;
`;

const inputWrap = css`
    min-width: 0;
`;

const filterHolder = (theme: Theme) => css`
    position: relative;
    width: 100%;

    @media (max-width: calc(${theme.breakpoints.md} - 1px)) {
        padding: 0;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
`;

const filterLabel = (theme: Theme) => css`
    display: none;

    &:focus {
        outline: none;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
`;

const filterLabelText = css`
    padding-right: 1rem;
    min-width: 0;
`;

interface IChildHolderProps {
    rightDropdown?: boolean;
}

const ChildHolder = styled.div<IChildHolderProps>`
    ${({theme, rightDropdown}) => css`
        background: #fff;
        padding: 1rem 2rem;

        .range-input-lower,
        .range-input-upper {
            margin-bottom: 10px;
        }

        @media (min-width: ${theme.breakpoints.md}) {
            ${fadeInAnimation("0.2s")};

            position: absolute;
            top: 6rem;
            z-index: 1001;
            right: ${rightDropdown ? 0 : "unset"};
            left: ${rightDropdown ? "unset" : 0};

            padding: 2rem 3rem;
            ${borderRadius(4)};
            min-width: 36rem;
            max-height: 60rem;
        }
    `}
`;

const aplaStyle = css`
    position: fixed;
    inset: 0;
    z-index: 50;
    ${borderRadius(2)};
    overflow: hidden;
`;
