import {Fragment} from "react";

import {rpAppPath} from "@pg-mono/rp-routes";

import {addSuccessNotification} from "../../notifications/utils/add_success_notification";

const favouritesLink = rpAppPath.favouriteList;

export const addOfferToFavouritesSuccess = addSuccessNotification(
    <Fragment>
        Dodałeś inwestycję do Twojej listy <br /> <br />
        <strong>
            <a href={favouritesLink} target="_blank" title="Ulubione" style={{textDecoration: "underline"}}>
                Zobacz listę
            </a>
        </strong>
        <br />
    </Fragment>
);

export const addPropertyToFavouritesSuccess = addSuccessNotification(
    <Fragment>
        Dodałeś nieruchomość do Porównywarki <br /> <br />
        <strong>
            <a href={favouritesLink} target="_blank" title="Ulubione" style={{textDecoration: "underline"}}>
                Zobacz listę
            </a>
        </strong>
        <br />
    </Fragment>
);

export const removeOfferFromFavouritesSuccess = addSuccessNotification("Usunąłeś inwestycję z Twojej listy");

export const removePropertyFromFavouritesSuccess = addSuccessNotification("Usunąłeś nieruchomość z Porównywarki");
import {Fragment} from "react";

import {rpAppPath} from "@pg-mono/rp-routes";

import {addSuccessNotification} from "../../notifications/utils/add_success_notification";

const favouritesLink = rpAppPath.favouriteList;

export const addOfferToFavouritesSuccess = addSuccessNotification(
    <Fragment>
        Dodałeś inwestycję do Twojej listy <br /> <br />
        <strong>
            <a href={favouritesLink} target="_blank" title="Ulubione" style={{textDecoration: "underline"}}>
                Zobacz listę
            </a>
        </strong>
        <br />
    </Fragment>
);

export const addPropertyToFavouritesSuccess = addSuccessNotification(
    <Fragment>
        Dodałeś nieruchomość do Porównywarki <br /> <br />
        <strong>
            <a href={favouritesLink} target="_blank" title="Ulubione" style={{textDecoration: "underline"}}>
                Zobacz listę
            </a>
        </strong>
        <br />
    </Fragment>
);

export const removeOfferFromFavouritesSuccess = addSuccessNotification("Usunąłeś inwestycję z Twojej listy");

export const removePropertyFromFavouritesSuccess = addSuccessNotification("Usunąłeś nieruchomość z Porównywarki");
