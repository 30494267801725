export enum IPublicTransportType {
    SUBWAY = "subway",
    BUS = "bus",
    TRAIN = "train",
    TRAM = "tram"
}
export enum IPublicTransportType {
    SUBWAY = "subway",
    BUS = "bus",
    TRAIN = "train",
    TRAM = "tram"
}
