import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const PoiTrainIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M2 1v6.4h1.067V9H3.6v-.533h2.667V9H6.8V7.4h1.067V1H2Zm2.667.533v2.134H2.533V1.533h2.134Zm1.6 6.4H3.6V7.4h2.667v.533ZM2.533 6.867V4.2h4.8v2.667h-4.8Zm4.8-3.2H5.2V1.533h2.133v2.134Z" />
            <path d="M3.6 5.267h-.533V5.8H3.6v-.533ZM6.8 5.267h-.533V5.8H6.8v-.533Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const PoiTrainIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M2 1v6.4h1.067V9H3.6v-.533h2.667V9H6.8V7.4h1.067V1H2Zm2.667.533v2.134H2.533V1.533h2.134Zm1.6 6.4H3.6V7.4h2.667v.533ZM2.533 6.867V4.2h4.8v2.667h-4.8Zm4.8-3.2H5.2V1.533h2.133v2.134Z" />
            <path d="M3.6 5.267h-.533V5.8H3.6v-.533ZM6.8 5.267h-.533V5.8H6.8v-.533Z" />
        </SvgIcon>
    );
};
