import {ISimhomeAreaType} from "../types/isimhome_property";
import {ISimhomeRoomTypeName} from "./get_simhome_room_type";

type IRPAreaType = "garden" | "room" | "bathroom" | "kitchen" | "additional";

export function getRpAreaType(areaType: ISimhomeAreaType, roomType: ISimhomeRoomTypeName): IRPAreaType {
    if (areaType === ISimhomeAreaType.LIVING_SPACE && (roomType === "room" || roomType === "other")) {
        return "room";
    }

    if (areaType === ISimhomeAreaType.LIVING_SPACE && roomType === "bathroom") {
        return "bathroom";
    }

    if (areaType === ISimhomeAreaType.LIVING_SPACE && roomType === "kitchen") {
        return "kitchen";
    }

    if (areaType === ISimhomeAreaType.ADDITIONAL_AREA) {
        return "garden";
    }

    return "additional";
}
import {ISimhomeAreaType} from "../types/isimhome_property";
import {ISimhomeRoomTypeName} from "./get_simhome_room_type";

type IRPAreaType = "garden" | "room" | "bathroom" | "kitchen" | "additional";

export function getRpAreaType(areaType: ISimhomeAreaType, roomType: ISimhomeRoomTypeName): IRPAreaType {
    if (areaType === ISimhomeAreaType.LIVING_SPACE && (roomType === "room" || roomType === "other")) {
        return "room";
    }

    if (areaType === ISimhomeAreaType.LIVING_SPACE && roomType === "bathroom") {
        return "bathroom";
    }

    if (areaType === ISimhomeAreaType.LIVING_SPACE && roomType === "kitchen") {
        return "kitchen";
    }

    if (areaType === ISimhomeAreaType.ADDITIONAL_AREA) {
        return "garden";
    }

    return "additional";
}
