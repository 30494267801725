import {IPropertyPlanImage} from "../types/IPropertyPlanImage";

export const getPropertyPlanImages = (propertyPlanPages: {image: Pick<IPropertyPlanImage, "p_img_560" | "p_img_980" | "p_img_1500">}[]) => {
    return propertyPlanPages.map(({image}) => {
        return {
            srcSm: image.p_img_560,
            srcMd: image.p_img_980,
            srcLg: image.p_img_1500
        };
    });
};
import {IPropertyPlanImage} from "../types/IPropertyPlanImage";

export const getPropertyPlanImages = (propertyPlanPages: {image: Pick<IPropertyPlanImage, "p_img_560" | "p_img_980" | "p_img_1500">}[]) => {
    return propertyPlanPages.map(({image}) => {
        return {
            srcSm: image.p_img_560,
            srcMd: image.p_img_980,
            srcLg: image.p_img_1500
        };
    });
};
