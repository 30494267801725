import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize as crs, listUnStyled, onDesktop, pv} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {areaFormat} from "@pg-mono/string-utils";

import {ISimhomeAreaType, ISimhomeRoom} from "../../types/isimhome_property";
import {getRpAreaColor} from "../../utils/get_rp_area_color";

interface IProps {
    rooms: ISimhomeRoom[];
    selectedAreaId: string | null;
    handleSelectArea: (areaId: string | null) => void;
    lastAreaIndex?: number;
    showAreaValues?: boolean;
}

export function PropertyPlanAreaList(props: IProps) {
    const {rooms, selectedAreaId, handleSelectArea, lastAreaIndex, showAreaValues} = props;

    return (
        <ul css={listUnStyled}>
            {rooms.map((room, index) => {
                const areaIndex = lastAreaIndex ? lastAreaIndex + index : index;

                return (
                    <li
                        key={`pplr${room.planner_id}`}
                        css={roomListItem}
                        onClick={() => {
                            handleSelectArea(room.planner_id);
                        }}
                    >
                        <div css={roomStyle}>
                            <RoomNumber
                                variant="body_copy_2"
                                areaType={room.area_type}
                                roomType={room.room_type}
                                isSelected={room.planner_id === selectedAreaId}
                                as="span"
                            >
                                {areaIndex + 1}
                            </RoomNumber>
                            <Text variant="body_copy_2" as="span" css={roomName}>
                                {room.name_pl}
                            </Text>
                            {showAreaValues && (
                                <Text variant="body_copy_1" as="span">
                                    <strong>{areaFormat(room.area, {precision: 2})}</strong>
                                </Text>
                            )}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}

//  Styles

const roomListItem = (theme: Theme) => css`
    ${pv(0.5)};
    border-bottom: 1px solid ${theme.colors.gray[200]};
`;

const roomStyle = (theme: Theme) => css`
    display: flex;
    column-gap: ${crs(1)};
    align-items: center;

    ${onDesktop(css`
        cursor: pointer;
        &:hover {
            background: ${theme.colors.gray[200]};
        }
    `)}
`;

const roomName = css`
    flex: 1 1 auto;
`;

interface IRoomNumberProps {
    areaType: ISimhomeAreaType;
    roomType: number | null;
    isSelected: boolean;
}

const RoomNumber = styled(Text)<IRoomNumberProps>`
    ${({theme, areaType, roomType, isSelected}) => css`
        flex: 0 0 auto;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: ${crs(3)};
        height: ${crs(3)};
        transition: background-color ${theme.transition.timingFunction} ${theme.transition.duration};
        background: ${getRpAreaColor(areaType, roomType, isSelected, theme)};
    `}
`;
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize as crs, listUnStyled, onDesktop, pv} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {areaFormat} from "@pg-mono/string-utils";

import {ISimhomeAreaType, ISimhomeRoom} from "../../types/isimhome_property";
import {getRpAreaColor} from "../../utils/get_rp_area_color";

interface IProps {
    rooms: ISimhomeRoom[];
    selectedAreaId: string | null;
    handleSelectArea: (areaId: string | null) => void;
    lastAreaIndex?: number;
    showAreaValues?: boolean;
}

export function PropertyPlanAreaList(props: IProps) {
    const {rooms, selectedAreaId, handleSelectArea, lastAreaIndex, showAreaValues} = props;

    return (
        <ul css={listUnStyled}>
            {rooms.map((room, index) => {
                const areaIndex = lastAreaIndex ? lastAreaIndex + index : index;

                return (
                    <li
                        key={`pplr${room.planner_id}`}
                        css={roomListItem}
                        onClick={() => {
                            handleSelectArea(room.planner_id);
                        }}
                    >
                        <div css={roomStyle}>
                            <RoomNumber
                                variant="body_copy_2"
                                areaType={room.area_type}
                                roomType={room.room_type}
                                isSelected={room.planner_id === selectedAreaId}
                                as="span"
                            >
                                {areaIndex + 1}
                            </RoomNumber>
                            <Text variant="body_copy_2" as="span" css={roomName}>
                                {room.name_pl}
                            </Text>
                            {showAreaValues && (
                                <Text variant="body_copy_1" as="span">
                                    <strong>{areaFormat(room.area, {precision: 2})}</strong>
                                </Text>
                            )}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}

//  Styles

const roomListItem = (theme: Theme) => css`
    ${pv(0.5)};
    border-bottom: 1px solid ${theme.colors.gray[200]};
`;

const roomStyle = (theme: Theme) => css`
    display: flex;
    column-gap: ${crs(1)};
    align-items: center;

    ${onDesktop(css`
        cursor: pointer;
        &:hover {
            background: ${theme.colors.gray[200]};
        }
    `)}
`;

const roomName = css`
    flex: 1 1 auto;
`;

interface IRoomNumberProps {
    areaType: ISimhomeAreaType;
    roomType: number | null;
    isSelected: boolean;
}

const RoomNumber = styled(Text)<IRoomNumberProps>`
    ${({theme, areaType, roomType, isSelected}) => css`
        flex: 0 0 auto;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: ${crs(3)};
        height: ${crs(3)};
        transition: background-color ${theme.transition.timingFunction} ${theme.transition.duration};
        background: ${getRpAreaColor(areaType, roomType, isSelected, theme)};
    `}
`;
