import {getExternalRequest} from "@pg-mono/request";

import {osmApiUrl} from "../../app/read_rp_environment_variables";
import {IPublicTransportRouteWithStops} from "../types/IPublicTransportRouteWithStops";

export interface IGetRouteParams {
    poiId: number;
}

export async function getRouteGeometry(params: IGetRouteParams) {
    if (osmApiUrl === null) {
        throw new Error("env: OSM_API_URL is not defined");
    }

    const url = `${osmApiUrl}/geo-assets/poi/${params.poiId}/route-geometry/`;

    return getExternalRequest({}, url, 30000) as Promise<IPublicTransportRouteWithStops>;
}
import {getExternalRequest} from "@pg-mono/request";

import {osmApiUrl} from "../../app/read_rp_environment_variables";
import {IPublicTransportRouteWithStops} from "../types/IPublicTransportRouteWithStops";

export interface IGetRouteParams {
    poiId: number;
}

export async function getRouteGeometry(params: IGetRouteParams) {
    if (osmApiUrl === null) {
        throw new Error("env: OSM_API_URL is not defined");
    }

    const url = `${osmApiUrl}/geo-assets/poi/${params.poiId}/route-geometry/`;

    return getExternalRequest({}, url, 30000) as Promise<IPublicTransportRouteWithStops>;
}
