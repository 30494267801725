import React, {forwardRef} from "react";
import classNames from "classnames";

import {InputField} from "@pg-design/inputs-module";

import {inputHolder, rangeHolder, separator} from "./RangeInput.module.css";

export interface IRange<T> {
    lower: T;
    upper: T;
    bounds?: string;
}

type RangeLimitValue = string | number;

interface IProps {
    className?: string;
    name: string;
    value: IRange<RangeLimitValue>;
    onChange: (name: string, value: IRange<RangeLimitValue>) => void;
}

export const RangeInput = forwardRef<HTMLDivElement, IProps>((props, inputForwardedRef) => {
    const {name, value, className, onChange} = props;

    const handlers = {
        onChange: (inputName: string, fieldValue: string) => {
            if (inputName === `${name}__lower`) {
                onChange(name, {...value, lower: fieldValue});
            }
            if (inputName === `${name}__upper`) {
                onChange(name, {...value, upper: fieldValue});
            }
        },
        preventNonDigitChars: (event: React.KeyboardEvent) => {
            const notAllowedCharCodes = ["Minus", "Period", "NumpadAdd", "NumpadSubtract", "Comma", "Equal"];

            if (notAllowedCharCodes.includes(event.code)) {
                event.preventDefault();
            }
        }
    };

    return (
        <div className={classNames(rangeHolder, className)} ref={inputForwardedRef}>
            <div className={classNames(inputHolder, "range-input-lower")}>
                <InputField
                    name={`${name}__lower`}
                    value={value && value.lower}
                    error={undefined}
                    onChange={(event) => {
                        handlers.onChange(event.currentTarget.name, event.currentTarget.value);
                    }}
                    placeholder="Od"
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    onKeyPress={handlers.preventNonDigitChars}
                />
            </div>

            <span className={classNames(separator)}>-</span>

            <div className={classNames(inputHolder, "range-input-upper")}>
                <InputField
                    name={`${name}__upper`}
                    value={value && value.upper}
                    error={undefined}
                    onChange={(event) => {
                        handlers.onChange(event.currentTarget.name, event.currentTarget.value);
                    }}
                    placeholder="Do"
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    onKeyPress={handlers.preventNonDigitChars}
                />
            </div>
        </div>
    );
});
import React, {forwardRef} from "react";
import classNames from "classnames";

import {InputField} from "@pg-design/inputs-module";

import {inputHolder, rangeHolder, separator} from "./RangeInput.module.css";

export interface IRange<T> {
    lower: T;
    upper: T;
    bounds?: string;
}

type RangeLimitValue = string | number;

interface IProps {
    className?: string;
    name: string;
    value: IRange<RangeLimitValue>;
    onChange: (name: string, value: IRange<RangeLimitValue>) => void;
}

export const RangeInput = forwardRef<HTMLDivElement, IProps>((props, inputForwardedRef) => {
    const {name, value, className, onChange} = props;

    const handlers = {
        onChange: (inputName: string, fieldValue: string) => {
            if (inputName === `${name}__lower`) {
                onChange(name, {...value, lower: fieldValue});
            }
            if (inputName === `${name}__upper`) {
                onChange(name, {...value, upper: fieldValue});
            }
        },
        preventNonDigitChars: (event: React.KeyboardEvent) => {
            const notAllowedCharCodes = ["Minus", "Period", "NumpadAdd", "NumpadSubtract", "Comma", "Equal"];

            if (notAllowedCharCodes.includes(event.code)) {
                event.preventDefault();
            }
        }
    };

    return (
        <div className={classNames(rangeHolder, className)} ref={inputForwardedRef}>
            <div className={classNames(inputHolder, "range-input-lower")}>
                <InputField
                    name={`${name}__lower`}
                    value={value && value.lower}
                    error={undefined}
                    onChange={(event) => {
                        handlers.onChange(event.currentTarget.name, event.currentTarget.value);
                    }}
                    placeholder="Od"
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    onKeyPress={handlers.preventNonDigitChars}
                />
            </div>

            <span className={classNames(separator)}>-</span>

            <div className={classNames(inputHolder, "range-input-upper")}>
                <InputField
                    name={`${name}__upper`}
                    value={value && value.upper}
                    error={undefined}
                    onChange={(event) => {
                        handlers.onChange(event.currentTarget.name, event.currentTarget.value);
                    }}
                    placeholder="Do"
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    onKeyPress={handlers.preventNonDigitChars}
                />
            </div>
        </div>
    );
});
