import {useState} from "react";
import {Interpolation, Theme} from "@emotion/react";

import {FilterFooter} from "../../../search/components/filters/SearchFiltersFooter";
import {FloorChoicesSwitch} from "../../../search/components/FloorChoicesSwitch";
import {getFloorChoiceLabel} from "../../../search/utils/get_label";
import {FakeSelectWithModal} from "./FakeSelectWithModal";

interface IProps {
    id: string;
    initialValue?: IFilterValue;
    rightDropdown?: boolean;
    onConfirm?: (value: IFilterValue) => void;
    handleClear?: () => void;
    aplaCss?: Interpolation<Theme>;
    labelRenderer?: (value?: IFilterValue) => Parameters<typeof FakeSelectWithModal>[0]["label"];
    onDropdownOpen?: () => void;
    onDropdownClose?: () => void;
    className?: string;
}

type IFilterValue = number[];
const emptyValue: IFilterValue = [];
const name = "floors";

export function FloorFilter(props: IProps) {
    const {id, initialValue, onConfirm, handleClear, rightDropdown, className} = props;
    const [value, setValue] = useState<IFilterValue>(initialValue ? initialValue : emptyValue);

    const handlers = {
        confirm: (values: IFilterValue) => {
            if (onConfirm) {
                onConfirm(values);
            }
        },
        clear: () => {
            setValue(emptyValue);

            if (handleClear) {
                handleClear();
            }
        }
    };

    return (
        <FakeSelectWithModal
            rightDropdown={rightDropdown}
            aplaCss={props.aplaCss}
            onDropdownOpen={props.onDropdownOpen}
            onDropdownClose={props.onDropdownClose}
            label={props.labelRenderer ? props.labelRenderer(value) : getFloorChoiceLabel(value)}
            isEmpty={value.length === 0}
            onClear={handlers.clear}
            className={className}
        >
            {(props) => (
                <>
                    <FloorChoicesSwitch
                        name={name}
                        id={id}
                        value={value}
                        onChange={(_, value) => {
                            setValue(value);
                        }}
                    />

                    <FilterFooter
                        onCancel={() => {
                            handlers.clear();
                            props.close();
                        }}
                        onSave={() => {
                            handlers.confirm(value);
                            props.close();
                        }}
                    />
                </>
            )}
        </FakeSelectWithModal>
    );
}
import {useState} from "react";
import {Interpolation, Theme} from "@emotion/react";

import {FilterFooter} from "../../../search/components/filters/SearchFiltersFooter";
import {FloorChoicesSwitch} from "../../../search/components/FloorChoicesSwitch";
import {getFloorChoiceLabel} from "../../../search/utils/get_label";
import {FakeSelectWithModal} from "./FakeSelectWithModal";

interface IProps {
    id: string;
    initialValue?: IFilterValue;
    rightDropdown?: boolean;
    onConfirm?: (value: IFilterValue) => void;
    handleClear?: () => void;
    aplaCss?: Interpolation<Theme>;
    labelRenderer?: (value?: IFilterValue) => Parameters<typeof FakeSelectWithModal>[0]["label"];
    onDropdownOpen?: () => void;
    onDropdownClose?: () => void;
    className?: string;
}

type IFilterValue = number[];
const emptyValue: IFilterValue = [];
const name = "floors";

export function FloorFilter(props: IProps) {
    const {id, initialValue, onConfirm, handleClear, rightDropdown, className} = props;
    const [value, setValue] = useState<IFilterValue>(initialValue ? initialValue : emptyValue);

    const handlers = {
        confirm: (values: IFilterValue) => {
            if (onConfirm) {
                onConfirm(values);
            }
        },
        clear: () => {
            setValue(emptyValue);

            if (handleClear) {
                handleClear();
            }
        }
    };

    return (
        <FakeSelectWithModal
            rightDropdown={rightDropdown}
            aplaCss={props.aplaCss}
            onDropdownOpen={props.onDropdownOpen}
            onDropdownClose={props.onDropdownClose}
            label={props.labelRenderer ? props.labelRenderer(value) : getFloorChoiceLabel(value)}
            isEmpty={value.length === 0}
            onClear={handlers.clear}
            className={className}
        >
            {(props) => (
                <>
                    <FloorChoicesSwitch
                        name={name}
                        id={id}
                        value={value}
                        onChange={(_, value) => {
                            setValue(value);
                        }}
                    />

                    <FilterFooter
                        onCancel={() => {
                            handlers.clear();
                            props.close();
                        }}
                        onSave={() => {
                            handlers.confirm(value);
                            props.close();
                        }}
                    />
                </>
            )}
        </FakeSelectWithModal>
    );
}
