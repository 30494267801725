import {IRange} from "@pg-design/inputs-module";
import {decimalSeparatorThousand} from "@pg-mono/string-utils";

interface IAreaFilterLabelText {
    label: string;
    text: string | null;
}

export function getAreaFilterLabelText(value: IRange<string>, defaultLabel: string): IAreaFilterLabelText {
    const {lower, upper} = value;

    if (lower && upper) {
        return {
            label: "Powierzchnia",
            text: `${decimalSeparatorThousand(lower)} - ${decimalSeparatorThousand(upper)}`
        };
    }

    if (lower) {
        return {
            label: "Powierzchnia",
            text: ` od ${decimalSeparatorThousand(lower)}`
        };
    }

    if (upper) {
        return {
            label: "Powierzchnia",
            text: ` do ${decimalSeparatorThousand(upper)}`
        };
    }

    return {
        label: defaultLabel,
        text: null
    };
}
import {IRange} from "@pg-design/inputs-module";
import {decimalSeparatorThousand} from "@pg-mono/string-utils";

interface IAreaFilterLabelText {
    label: string;
    text: string | null;
}

export function getAreaFilterLabelText(value: IRange<string>, defaultLabel: string): IAreaFilterLabelText {
    const {lower, upper} = value;

    if (lower && upper) {
        return {
            label: "Powierzchnia",
            text: `${decimalSeparatorThousand(lower)} - ${decimalSeparatorThousand(upper)}`
        };
    }

    if (lower) {
        return {
            label: "Powierzchnia",
            text: ` od ${decimalSeparatorThousand(lower)}`
        };
    }

    if (upper) {
        return {
            label: "Powierzchnia",
            text: ` do ${decimalSeparatorThousand(upper)}`
        };
    }

    return {
        label: defaultLabel,
        text: null
    };
}
